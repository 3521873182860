import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {alfInvoiceStatusTypes, alfInvoiceTypes} from '@ace-de/eua-entity-types';
import {resolveRoute, useHistory} from '@computerrock/formation-router';
import {useStyles, Panel, Pill, Table, TableHead, TableRow, TableCell, TableBody, TableRowCollapsable, CollapsedRowContentItem, LabelWithIcon, ActionMenu, ActionMenuOption} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, moreIcon, multipleServiceIcon} from '@ace-de/ui-components/icons';
import {DropDownTrigger, withDropDownProvider} from '@ace-de/ui-components/overlays';
import invoiceStatusPillColorMap from '../../invoices/invoiceStatusPillColorMap';
import {serviceIcons} from '../../invoices/ui-elements/serviceIcons';
import routePaths from '../../routePaths';
import * as invoiceActionTypes from '../../invoices/invoiceActionTypes';
import * as communicationActionTypes from '../../communication/communicationActionTypes';
import leaAccessControl from '../../leaAccessControl';
import {leaFeatureActions, leaFeatures} from '../../application/leaFeatures';
import scOverviewScreenTabs from '../scOverviewScreenTabs';

const actionOptions = {
    EDIT_INVOICE: 'EDIT_INVOICE',
    START_MANUAL_CHECK: 'START_MANUAL_CHECK',
    VIEW: 'VIEW',
    CREATE_INVOICE_FOR_ASSIGNMENT: 'CREATE_INVOICE_FOR_ASSIGNMENT',
    FORWARD_INVOICE: 'FORWARD_INVOICE',
    CLOSE_INVOICE: 'CLOSE_INVOICE',
    RESET_INVOICE: 'RESET_INVOICE',
    RESET_DECLINED_INVOICE: 'RESET_DECLINED_INVOICE',
    CORRECT_INVOICE: 'CORRECT_INVOICE',
    RESOLVE_ESTIMATION: 'RESOLVE_ESTIMATION',
    COMMUNICATION: 'COMMUNICATION',
    CLOSE_BBZ: 'CLOSE_BBZ',
};

const hiddenPropertyStatuses = [alfInvoiceStatusTypes.OPEN, alfInvoiceStatusTypes.CLOSED];

const InvoiceDetailsPanel = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateComponent = createTranslateShorthand('invoice_details_panel');
    const {forwardInvoice, initiateInvoiceCorrection, initiateInvoiceEstimation} = props;
    const {updateInvoiceStatus, createInvoiceForAssignment, initiateSendMessage, serviceCaseId} = props;
    const {invoiceGroup, index, accountPartyId, accountPartyName, hasNewTabLinkout, initiateClosingOfBBZ} = props;
    const isActionMenuAvailable = leaAccessControl.grantFeatureAccess(
        leaFeatures.INVOICE,
        leaFeatureActions.UPDATE,
    );

    const displayAmount = invoice => {
        const {lines} = invoice;
        const requestedAmounts = lines.map(invoiceLine => invoiceLine.requestedAmount);
        const approvedAmounts = lines.map(invoiceLine => invoiceLine.approvedAmount);

        switch (invoice.status) {
            case alfInvoiceStatusTypes.OPEN:
            case alfInvoiceStatusTypes.DECLINED:
            case alfInvoiceStatusTypes.CLOSED: {
                return '0';
            }
            case alfInvoiceStatusTypes.IN_CREATION:
            case alfInvoiceStatusTypes.MANUAL_CHECK:
            case alfInvoiceStatusTypes.PAID: {
                const requestedAmount = requestedAmounts.length > 0
                    ? requestedAmounts.reduce((currentSum, amount) => {
                        return currentSum + (amount ? parseFloat(amount) : 0);
                    })
                    : 0;
                return Number(requestedAmount).toFixed(2).replace('.', ',');
            }
            case alfInvoiceStatusTypes.APPROVED: {
                const approvedAmount = approvedAmounts.length > 0
                    ? approvedAmounts.reduce((currentSum, amount) => {
                        return currentSum + (amount ? parseFloat(amount) : 0);
                    })
                    : 0;
                return Number(approvedAmount).toFixed(2).replace('.', ',');
            }
            default:
                return '-';
        }
    };

    const handleOnOptionSelect = (invoice, action) => {
        let routePath;
        let newStatus;

        switch (action) {
            case actionOptions.EDIT_INVOICE: {
                newStatus = invoice.status === alfInvoiceStatusTypes.MANUAL_CHECK
                    ? alfInvoiceStatusTypes.IN_CREATION : null;
                routePath = routePaths.INVOICES;
                break;
            }

            case actionOptions.VIEW:
            case actionOptions.START_MANUAL_CHECK: {
                routePath = routePaths.INVOICE_OVERVIEW;
                break;
            }

            case actionOptions.FORWARD_INVOICE: {
                forwardInvoice({invoiceId: invoice.id});
                break;
            }

            case actionOptions.CLOSE_INVOICE: {
                newStatus = alfInvoiceStatusTypes.CLOSED;
                break;
            }

            case actionOptions.RESET_INVOICE: {
                newStatus = alfInvoiceStatusTypes.OPEN;
                break;
            }

            case actionOptions.RESET_DECLINED_INVOICE: {
                newStatus = alfInvoiceStatusTypes.MANUAL_CHECK;
                break;
            }

            case actionOptions.CREATE_INVOICE_FOR_ASSIGNMENT: {
                createInvoiceForAssignment({
                    invoiceId: invoice.id,
                    serviceCaseId: invoice.serviceCaseId,
                });
                break;
            }

            case actionOptions.CORRECT_INVOICE: {
                initiateInvoiceCorrection({
                    redirectionPath: routePaths.SERVICE_CASES_OVERVIEW,
                    invoiceId: invoice.id,
                    activeTab: scOverviewScreenTabs.OVERVIEW,
                });
                break;
            }

            case actionOptions.RESOLVE_ESTIMATION: {
                initiateInvoiceEstimation({redirectionPath: routePaths.INVOICES, invoiceId: invoice.id});
                break;
            }

            case actionOptions.COMMUNICATION: {
                initiateSendMessage({invoiceId: invoice.id, serviceCaseId});
                break;
            }

            case actionOptions.CLOSE_BBZ: {
                initiateClosingOfBBZ({invoiceId: invoice.id});
                break;
            }

            default:
                break;
        }

        if (newStatus) {
            updateInvoiceStatus({
                invoiceStatusData: {
                    status: newStatus,
                },
                invoice,
                routePath,
                history,
            });
            return;
        }

        if (routePath) {
            if (hasNewTabLinkout) {
                const {pathname, search} = resolveRoute(routePath,
                    {serviceCaseId: invoice.serviceCaseId, invoiceId: invoice.id},
                    {search: ''});
                window.open(pathname + search, '_blank');
                return;
            }

            history.push(resolveRoute(routePath, {serviceCaseId: invoice.serviceCaseId, invoiceId: invoice.id}, {search: ''}));
        }
    };

    const getActionOptions = invoice => {
        if (invoice.type === alfInvoiceTypes.VKG || invoice.type === alfInvoiceTypes.EKG) {
            return [actionOptions.VIEW];
        }

        switch (invoice.status) {
            case alfInvoiceStatusTypes.IN_CREATION: {
                return [
                    actionOptions.EDIT_INVOICE,
                    actionOptions.CLOSE_INVOICE,
                    actionOptions.COMMUNICATION,
                ];
            }

            case alfInvoiceStatusTypes.BC_ERROR:
            case alfInvoiceStatusTypes.MANUAL_CHECK: {
                return [
                    actionOptions.START_MANUAL_CHECK,
                    actionOptions.EDIT_INVOICE,
                    actionOptions.COMMUNICATION,
                ];
            }

            case alfInvoiceStatusTypes.APPROVED: {
                return [
                    ...(invoice.type === alfInvoiceTypes.EKR || invoice.type === alfInvoiceTypes.VKR
                        ? [actionOptions.VIEW] : []),
                    ...((invoice.type === alfInvoiceTypes.EKR || invoice.type === alfInvoiceTypes.VKR)
                        && !invoice.hasRelatedInvoice ? [actionOptions.CORRECT_INVOICE] : []),
                    ...(invoice.type === alfInvoiceTypes.EKR && !invoice.salesInvoiceId
                        ? [actionOptions.FORWARD_INVOICE] : []),
                    ...(invoice.type === alfInvoiceTypes.BBZ && !invoice.hasRelatedInvoice
                        ? [actionOptions.RESOLVE_ESTIMATION] : []),
                    ...(invoice.type === alfInvoiceTypes.BBZ && !invoice.hasRelatedInvoice
                        ? [actionOptions.CLOSE_BBZ] : []),
                    actionOptions.COMMUNICATION,
                ];
            }

            case alfInvoiceStatusTypes.OPEN: {
                return [
                    actionOptions.CREATE_INVOICE_FOR_ASSIGNMENT,
                    actionOptions.CLOSE_INVOICE,
                    actionOptions.COMMUNICATION,
                ];
            }

            case alfInvoiceStatusTypes.BOOKED:
            case alfInvoiceStatusTypes.PAID: {
                return [
                    ...(invoice.type === alfInvoiceTypes.EKR || invoice.type === alfInvoiceTypes.VKR
                        ? [actionOptions.VIEW] : []),
                    ...((invoice.type === alfInvoiceTypes.EKR || invoice.type === alfInvoiceTypes.VKR)
                        && !invoice.hasRelatedInvoice ? [actionOptions.CORRECT_INVOICE] : []),
                    ...(invoice.type === alfInvoiceTypes.EKR ? [actionOptions.FORWARD_INVOICE] : []),
                    actionOptions.COMMUNICATION,
                ];
            }

            case alfInvoiceStatusTypes.CLOSED: {
                return [
                    actionOptions.VIEW,
                    actionOptions.COMMUNICATION,
                    ...(invoice.type !== alfInvoiceTypes.BBZ ? [actionOptions.RESET_INVOICE] : []),
                ];
            }

            case alfInvoiceStatusTypes.DECLINED: {
                return [
                    actionOptions.VIEW,
                    actionOptions.RESET_DECLINED_INVOICE,
                    actionOptions.COMMUNICATION,
                ];
            }

            default:
                return [];
        }
    };

    return (
        <Panel
            qaIdent={`invoice-details-panel-${index}`}
            title={`${index}. ${accountPartyName || ''} ${accountPartyId || ''}`}
            actions={(
                // todo add action in another story
                <div />
            )}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table qaIdent="invoice-details-panel">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={1} className={cx('ace-u-padding--left-32')} />
                        <TableCell colSpan={2} qaIdentPart="invoice-created-at">
                            {translateComponent('table_header.creation_date')}
                        </TableCell>
                        <TableCell colSpan={1} qaIdentPart="invoice-type">
                            {translateComponent('table_header.type')}
                        </TableCell>
                        <TableCell colSpan={1} qaIdentPart="invoice-channel">
                            {translateComponent('table_header.channel')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="invoice-id">
                            {translateComponent('table_header.invoice_id')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="invoice-status">
                            {translateComponent('table_header.status')}
                        </TableCell>
                        <TableCell colSpan={3} qaIdentPart="invoice-lines-service">
                            {translateComponent('table_header.service')}
                        </TableCell>
                        <TableCell colSpan={1} qaIdentPart="invoice-total-amount">
                            {translateComponent('table_header.price')}
                        </TableCell>
                        <TableCell colSpan={1} qaIdentPart="invoice-is-vat-included">
                            {translateComponent('table_header.is_vat_included')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="invoice-sales-invoice-id">
                            {translateComponent('table_header.invoice_forwarding')}
                        </TableCell>
                        {isActionMenuAvailable && (
                            <TableCell colSpan={2} qaIdentPart="invoice-action-menu" className={cx('ace-u-padding--right-32')}>
                                {translateComponent('table_header.action')}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoiceGroup.map(invoice => {
                        const actionOptions = getActionOptions(invoice);

                        return invoice.lines?.length <= 1 ? (
                            <TableRow key={invoice.id}>
                                <TableCell colSpan={1} className={cx('ace-u-padding--left-32')} />
                                <TableCell colSpan={2} qaIdentPart="invoice-created-at">
                                    {invoice?.createdAt || '-'}
                                </TableCell>
                                <TableCell colSpan={1} qaIdentPart="invoice-type">
                                    {!invoice.type || hiddenPropertyStatuses.includes(invoice.status) ? '-' : invoice.type}
                                </TableCell>
                                <TableCell colSpan={1} qaIdentPart="invoice-channel">
                                    {invoice?.channel || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-id">
                                    {hiddenPropertyStatuses.includes(invoice.status) || !invoice.id ? '-' : invoice.id}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-status">
                                    <Pill type={invoiceStatusPillColorMap[invoice.subStatus || invoice.status]}>
                                        {translate(`global.invoice_status.${invoice.subStatus
                                            ? invoice.subStatus.toLowerCase()
                                            : invoice.status.toLowerCase()}`)}
                                    </Pill>
                                </TableCell>
                                <TableCell colSpan={3} qaIdentPart="invoice-lines-service">
                                    {invoice.lines[0]?.service
                                        ? (
                                            <LabelWithIcon
                                                className={cx('ace-c-label-with-icon--icon-reverse')}
                                                label={translate(`global.service_type.${invoice.lines[0].service.toLowerCase()}`)}
                                            >
                                                <Icon icon={serviceIcons[invoice.lines[0].service]?.icon} />
                                            </LabelWithIcon>
                                        )
                                        : '-'
                                    }
                                </TableCell>
                                <TableCell colSpan={1} qaIdentPart="invoice-total-amount">
                                    <p
                                        className={cx('global!ace-u-typography--align-right', {
                                            'global!ace-u-typography--color-warning': invoice.type === alfInvoiceTypes.EKG || invoice.type === alfInvoiceTypes.VKG,
                                            'global!ace-u-typography--variant-body-bold': invoice.type === alfInvoiceTypes.EKG || invoice.type === alfInvoiceTypes.VKG,
                                        })}
                                    >
                                        {displayAmount(invoice)}
                                        <span> {translate('global.currency.euro')}</span>
                                    </p>
                                </TableCell>
                                <TableCell colSpan={1} qaIdentPart="invoice-is-vat-included">
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-flex--justify-content-center',
                                        ])}
                                    >
                                        {invoice.isVATIncluded ? translateComponent('table_cell.b') : translateComponent('table_cell.n')}
                                    </div>
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-sales-invoice-id">
                                    {invoice.salesInvoiceId ? translateComponent('table_cell.yes') : '-'}
                                </TableCell>
                                {isActionMenuAvailable && (
                                    <TableCell colSpan={2} qaIdentPart="invoice-action-menu" className={cx('ace-u-padding--right-32')}>
                                        {actionOptions.length > 0 && (
                                            <DropDownTrigger>
                                                <div>
                                                    <InteractiveIcon
                                                        icon={moreIcon}
                                                        className={cx('ace-c-interactive-icon--primary')}
                                                    />
                                                </div>
                                                <ActionMenu alignment="end">
                                                    <div
                                                        className={cx([
                                                            'global!ace-u-flex',
                                                            'global!ace-u-flex--direction-column',
                                                        ])}
                                                    >
                                                        {actionOptions.map(action => (
                                                            <div key={action}>
                                                                <ActionMenuOption
                                                                    onClick={() => {
                                                                        handleOnOptionSelect(invoice, action);
                                                                    }}
                                                                >
                                                                    {translateComponent(`dropdown_option_label.${action.toLowerCase()}`)}
                                                                </ActionMenuOption>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </ActionMenu>
                                            </DropDownTrigger>
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                        ) : (
                            <TableRowCollapsable
                                key={invoice.id}
                                headerCells={(
                                    <Fragment>
                                        <TableCell colSpan={2} qaIdentPart="invoice-created-at">
                                            {invoice?.createdAt || '-'}
                                        </TableCell>
                                        <TableCell colSpan={1} qaIdentPart="invoice-type">
                                            {invoice.status === alfInvoiceStatusTypes.OPEN || !invoice.type ? '-' : invoice.type}
                                        </TableCell>
                                        <TableCell colSpan={1} qaIdentPart="invoice-channel">
                                            {invoice?.channel || '-'}
                                        </TableCell>
                                        <TableCell colSpan={2} qaIdentPart="invoice-id">
                                            {invoice.status === alfInvoiceStatusTypes.OPEN || !invoice.id ? '-' : invoice.id}
                                        </TableCell>
                                        <TableCell colSpan={2} qaIdentPart="invoice-status">
                                            <Pill type={invoiceStatusPillColorMap[invoice.status]}>
                                                {translate(`global.invoice_status.${invoice.status.toLowerCase()}`)}
                                            </Pill>
                                        </TableCell>
                                        <TableCell colSpan={3} qaIdentPart="invoice-line-service">
                                            <LabelWithIcon
                                                className={cx('ace-c-label-with-icon--icon-reverse')}
                                                label={`${translate(`global.service_type.${invoice.lines[0]?.service?.toLowerCase()}`)} + ${invoice.lines.length - 1}`}
                                            >
                                                <Icon icon={multipleServiceIcon} />
                                            </LabelWithIcon>
                                        </TableCell>
                                        <TableCell colSpan={1} qaIdentPart="invoice-total-amount">
                                            <p
                                                className={cx('global!ace-u-typography--align-right', {
                                                    'global!ace-u-typography--color-warning': invoice.type === alfInvoiceTypes.EKG || invoice.type === alfInvoiceTypes.VKG,
                                                    'global!ace-u-typography--variant-body-bold': invoice.type === alfInvoiceTypes.EKG || invoice.type === alfInvoiceTypes.VKG,
                                                })}
                                            >
                                                {displayAmount(invoice)}
                                                <span> {translate('global.currency.euro')}</span>
                                            </p>
                                        </TableCell>
                                        <TableCell colSpan={1} qaIdentPart="invoice-is-vat-included">
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                    'global!ace-u-flex--justify-content-center',
                                                ])}
                                            >
                                                {invoice.isVATIncluded ? translateComponent('table_cell.b') : translateComponent('table_cell.n')}
                                            </div>
                                        </TableCell>
                                        <TableCell colSpan={2} qaIdentPart="invoice-sales-invoice-id">
                                            {invoice.salesInvoiceId ? translateComponent('table_cell.yes') : '-'}
                                        </TableCell>
                                        {isActionMenuAvailable && (
                                            <TableCell colSpan={2} qaIdentPart="invoice-action-menu" className={cx('ace-u-padding--right-32')}>
                                                <div
                                                    className={cx('global!ace-u-width--max-content')}
                                                    onClick={event => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    {actionOptions.length > 0 && (
                                                        <DropDownTrigger>
                                                            <div>
                                                                <InteractiveIcon
                                                                    icon={moreIcon}
                                                                    className={cx('ace-c-interactive-icon--primary')}
                                                                />
                                                            </div>
                                                            <ActionMenu alignment="end">
                                                                <div
                                                                    className={cx([
                                                                        'global!ace-u-flex',
                                                                        'global!ace-u-flex--direction-column',
                                                                    ])}
                                                                >
                                                                    {actionOptions.map(action => (
                                                                        <div key={action}>
                                                                            <ActionMenuOption
                                                                                onClick={() => {
                                                                                    handleOnOptionSelect(
                                                                                        invoice,
                                                                                        action,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {translateComponent(`dropdown_option_label.${action.toLowerCase()}`)}
                                                                            </ActionMenuOption>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </ActionMenu>
                                                        </DropDownTrigger>
                                                    )}
                                                </div>
                                            </TableCell>
                                        )}
                                    </Fragment>
                                )}
                                defaultState="collapsed"
                            >
                                {invoice.lines.map((invoiceLine, key) => {
                                    return (
                                        <CollapsedRowContentItem
                                            columnOffset={9}
                                            colSpan={3}
                                            key={`${invoice.id}-${key}`}
                                            qaIdentPart={invoiceLine.lineNo}
                                            indentClassName={cx('ace-u-padding--left-32')}
                                        >
                                            <TableCell colSpan={3} qaIdentPart="invoice-line-service" qaIdentPartPostfix={invoiceLine.lineNo}>
                                                {
                                                    invoiceLine.service
                                                        ? (
                                                            <LabelWithIcon
                                                                className={cx([
                                                                    'ace-c-label-with-icon--icon-reverse',
                                                                    'global!ace-u-typography--variant-body',
                                                                ])}
                                                                label={translate(`global.service_type.${invoiceLine.service.toLowerCase()}`)}
                                                            >
                                                                <Icon icon={serviceIcons[invoiceLine.service]?.icon} />
                                                            </LabelWithIcon>
                                                        )
                                                        : '-'
                                                }
                                            </TableCell>
                                            <TableCell colSpan={1} qaIdentPart="invoice-amount" qaIdentPartPostfix={invoiceLine.lineNo}>
                                                <p className={cx('global!ace-u-typography--align-right')}>
                                                    {Number(invoice.status === alfInvoiceStatusTypes.APPROVED
                                                        ? invoiceLine.approvedAmount
                                                        : invoiceLine.requestedAmount).toFixed(2).replace('.', ',')}
                                                    <span> {translate('global.currency.euro')}</span>
                                                </p>
                                            </TableCell>
                                            <TableCell colSpan={isActionMenuAvailable ? 5 : 3} />
                                        </CollapsedRowContentItem>
                                    );
                                })}
                            </TableRowCollapsable>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

InvoiceDetailsPanel.propTypes = {
    index: PropTypes.number.isRequired,
    accountPartyId: PropTypes.string.isRequired,
    invoiceGroup: PropTypes.array.isRequired,
    forwardInvoice: PropTypes.func.isRequired,
    updateInvoiceStatus: PropTypes.func.isRequired,
    createInvoiceForAssignment: PropTypes.func.isRequired,
    initiateSendMessage: PropTypes.func.isRequired,
    initiateInvoiceCorrection: PropTypes.func.isRequired,
    initiateInvoiceEstimation: PropTypes.func.isRequired,
    initiateClosingOfBBZ: PropTypes.func.isRequired,
    serviceCaseId: PropTypes.string.isRequired,
    hasNewTabLinkout: PropTypes.bool,
    accountPartyName: PropTypes.string,
};

InvoiceDetailsPanel.defaultProps = {
    hasNewTabLinkout: false,
    accountPartyName: '',
};

const mapDispatchToProps = dispatch => ({
    updateInvoiceStatus: payload => dispatch({
        type: invoiceActionTypes.UPDATE_INVOICE_STATUS,
        payload,
    }),
    forwardInvoice: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_FORWARD_FLOW,
        payload,
    }),
    createInvoiceForAssignment: payload => dispatch({
        type: invoiceActionTypes.CREATE_INVOICE_FOR_ASSIGNMENT,
        payload,
    }),
    initiateSendMessage: payload => dispatch({
        type: communicationActionTypes.INITIATE_SEND_MESSAGE_FLOW,
        payload,
    }),
    initiateInvoiceCorrection: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_CORRECTION_FLOW,
        payload,
    }),
    initiateInvoiceEstimation: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_ESTIMATION_FLOW,
        payload,
    }),
    initiateClosingOfBBZ: payload => dispatch({
        type: invoiceActionTypes.INITIATE_CLOSING_OF_BBZ_FLOW,
        payload,
    }),
});

export default withDropDownProvider(connect(null, mapDispatchToProps)(InvoiceDetailsPanel));
