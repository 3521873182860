import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ActionMenu, ActionMenuOption, ButtonPrimary, Panel, useStyles, ScrollableBlock} from '@ace-de/ui-components';
import {DropDownTrigger, withDropDownProvider} from '@ace-de/ui-components/overlays';
import {Icon, InteractiveIcon, uploadIcon, fileIcon, moreIcon} from '@ace-de/ui-components/icons';
import * as invoiceActionTypes from '../invoiceActionTypes';
import * as invoiceSelectors from '../invoiceSelectors';

const InvoiceDocumentUploadPanel = props => {
    const {cx} = useStyles(props);
    const {createTranslateShorthand, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('invoice_documents_panel');
    const {invoice, isReadOnly = false} = props;
    const {attachments} = invoice;
    const {initiateDocumentUploadFlow, initiateDocumentEditFlow, initiateDocumentDeleteFlow} = props;
    const {initiateDocumentDownload} = props;
    const actionOptions = {
        VIEW: 'VIEW',
        ...(!isReadOnly && {EDIT: 'EDIT'}),
        ...(!isReadOnly && {DELETE: 'DELETE'}),
    };

    const handleOnSelect = (value, attachment) => {
        if (value === actionOptions.VIEW) {
            initiateDocumentDownload({fileName: attachment.fileName, url: attachment.url});
        }
        if (value === actionOptions.EDIT) initiateDocumentEditFlow({attachment, invoiceId: invoice.id});
        if (value === actionOptions.DELETE) initiateDocumentDeleteFlow({attachment, invoiceId: invoice.id});
    };

    return (
        <Panel
            title={translatePanel('panel_title.documents')}
            actions={!isReadOnly && (
                <ButtonPrimary onClick={() => initiateDocumentUploadFlow({invoiceId: invoice.id})}>
                    <Icon
                        icon={uploadIcon}
                        className={cx([
                            'global!ace-u-margin--right-16',
                            'ace-c-icon--color-contrast',
                        ])}
                    />
                    {translatePanel('button_label.upload')}
                </ButtonPrimary>
            )}
            className={cx('global!ace-u-height--750')}
        >
            <ScrollableBlock isLabelDisabled={true} className={cx('ace-c-scrollable-block--full-bleed')}>
                {attachments?.length > 0 && attachments.map(attachment => (
                    <div
                        key={attachment.id}
                        className={cx([
                            'global!ace-u-padding--16-24',
                            'global!ace-u-margin--bottom-8',
                            'global!ace-u-width--full',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--justify-space-between',
                            'global!ace-u-box-skin--pending',
                        ])}
                    >
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                            ])}
                        >
                            <Icon icon={fileIcon} className={cx('global!ace-u-margin--right-16')} />
                            {attachment.description}
                            {attachment.attachmentType && ` (${translate(`global.document_type.${attachment.attachmentType.toLowerCase()}`)})`}
                        </div>
                        <DropDownTrigger>
                            <div>
                                <InteractiveIcon
                                    icon={moreIcon}
                                />
                            </div>
                            <ActionMenu alignment="end">
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-column',
                                    ])}
                                >
                                    {Object.values(actionOptions).map(action => (
                                        <div key={action}>
                                            <ActionMenuOption onClick={() => handleOnSelect(action, attachment)}>
                                                {translatePanel(`dropdown_option_label.${action.toLowerCase()}`)}
                                            </ActionMenuOption>
                                        </div>
                                    ))}
                                </div>
                            </ActionMenu>
                        </DropDownTrigger>
                    </div>
                ))}
            </ScrollableBlock>
        </Panel>
    );
};

InvoiceDocumentUploadPanel.propTypes = {
    invoice: PropTypes.object,
    isReadOnly: PropTypes.bool,
    initiateDocumentUploadFlow: PropTypes.func.isRequired,
    initiateDocumentEditFlow: PropTypes.func.isRequired,
    initiateDocumentDeleteFlow: PropTypes.func.isRequired,
    initiateDocumentDownload: PropTypes.func.isRequired,
};

InvoiceDocumentUploadPanel.defaultProps = {
    invoice: null,
    isReadOnly: false,
};

const mapStateToProps = (state, props) => {
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    return {
        invoice: getInvoice(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateDocumentUploadFlow: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_DOCUMENT_UPLOAD_FLOW,
        payload,
    }),
    initiateDocumentEditFlow: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_DOCUMENT_EDIT_FLOW,
        payload,
    }),
    initiateDocumentDeleteFlow: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_DOCUMENT_DELETE_FLOW,
        payload,
    }),
    initiateDocumentDownload: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_DOCUMENT_DOWNLOAD_FLOW,
        payload,
    }),
});

export default withRouter(withDropDownProvider(
    connect(mapStateToProps, mapDispatchToProps)(InvoiceDocumentUploadPanel),
));
