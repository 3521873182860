import {put, fork, take, select} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';

const loadServiceCase = function* loadServiceCase({payload}) {
    const {location, match} = payload;
    const {serviceCaseId} = match.params;
    const serviceCase = yield select(state => state.serviceCases.serviceCases[serviceCaseId]);

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const invoiceScreenMatch = matchPath(location.pathname, {
        path: routePaths.INVOICES,
        exact: true,
    });
    const serviceCaseOverviewScreenMatch = matchPath(location.pathname, {
        path: routePaths.SERVICE_CASES_OVERVIEW,
        exact: true,
    });

    if (!serviceCaseId
        || (invoiceScreenMatch && serviceCase)
        || (serviceCaseOverviewScreenMatch && serviceCase)
    ) return;

    if (location?.state?.isModalClosed && [modalIds.CONFIRM_VEHICLE_SELECTION_MODAL, modalIds.VEHICLE_DATA_MODAL]
        .includes(location?.state?.modalId)
    ) return;

    yield fork(fetchRequest,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST,
        leaAssignmentFlowService.getServiceCase,
        {serviceCaseId});

    const serviceCaseFetchActionResponse = yield take([
        serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST_FAILED,
    ]);

    if (!serviceCaseFetchActionResponse.error) {
        const {response} = serviceCaseFetchActionResponse.payload;
        const {serviceCaseDTO} = response;

        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_CASES,
            payload: {serviceCaseDTOs: [serviceCaseDTO]},
        });
    }
};

export default loadServiceCase;
