import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import getResourceEndpointKey from '../datafactoryResourceEndpoints';
import * as contactActionTypes from '../contactActionTypes';

const formatDatafactorySearchParams = (keyValuePairs = []) => {
    if (!keyValuePairs.length) return;

    const searchQueryParams = new URLSearchParams();
    keyValuePairs.forEach(([key, value]) => {
        searchQueryParams.append(`${key === 'postCode' ? 'postal_code' : key}`, value);
    });

    return searchQueryParams;
};

const searchContactAddressGeolocation = function* searchContactAddressGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const datafactoryService = serviceManager.loadService('datafactoryService');
    const {searchQueryString, key, addressQueryParamsMap} = payload;
    const selectedFields = Array.from(addressQueryParamsMap.keys());
    const isKeyStreet = key === 'street';

    if (selectedFields?.includes(key)) return;

    yield put({
        type: contactActionTypes.RESET_CONTACT_ADDRESS_CANDIDATES,
        payload: {key},
    });

    yield put({
        type: contactActionTypes.RESET_CONTACT_ADDRESS_CANDIDATES_SEARCH_ERROR,
        payload: {key},
    });

    const searchQueryParams = formatDatafactorySearchParams([
        ...(addressQueryParamsMap?.size ? Array.from(addressQueryParamsMap.entries()) : []),
        ...(searchQueryString ? [[key, searchQueryString]] : []),
    ]);

    const combined = [
        ...(addressQueryParamsMap?.size ? Array.from(addressQueryParamsMap.values()) : []),
        ...(searchQueryString ? [searchQueryString] : []),
    ].join(' ');

    yield fork(
        fetchRequest,
        contactActionTypes.SEARCH_CONTACT_ADDRESS_GEOLOCATION_REQUEST,
        isKeyStreet
            ? datafactoryService.searchAddressSuggestions
            : datafactoryService.searchAddressSuggestionsByField,
        !isKeyStreet ? {
            resourcePathKey: getResourceEndpointKey(selectedFields, key),
            searchQueryParams,
        } : {combined},
    );

    const accountPartyAddressGeolocation = yield take([
        contactActionTypes.SEARCH_CONTACT_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED,
        contactActionTypes.SEARCH_CONTACT_ADDRESS_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (accountPartyAddressGeolocation.error) {
        const {response} = accountPartyAddressGeolocation.payload;
        yield put({
            type: contactActionTypes.SET_CONTACT_ADDRESS_CANDIDATES_SEARCH_ERROR,
            payload: {key, errorMessage: response.errorMessage},
        });
        return;
    }

    const {response} = accountPartyAddressGeolocation.payload;
    const {addressCandidateDTOs} = response;

    yield put({
        type: contactActionTypes.SET_CONTACT_ADDRESS_CANDIDATES,
        payload: {
            addressCandidateDTOs,
            searchQueryString,
            key,
        },
    });
};

export default searchContactAddressGeolocation;
