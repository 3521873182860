import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {mafServiceTypes} from '@ace-de/eua-entity-types';
import {ContentBlock, ContentItem, useStyles} from '@ace-de/ui-components';
import MIAFormServicePanel from './ui-elements/MIAFormServicePanel';
import MIAFormAccommodationServicePanel from './ui-elements/MIAFormAccommodationServicePanel';
import MIAFormRentalCarServicePanel from './ui-elements/MIAFormRentalCarServicePanel';
import MIAFormAidServicePanel from './ui-elements/MIAFormAidServicePanel';
import MIAFormFurtherJourneyServicePanel from './ui-elements/MIAFormFurtherJourneyServicePanel';

const MIAFormTab = props => {
    const {cx} = useStyles();
    const {invoiceSubmission} = props;

    if (!invoiceSubmission || !invoiceSubmission.services) return null;

    const {services} = invoiceSubmission;
    const isDefaultExpanded = services.length === 1;

    return (
        <Fragment>
            {services?.map((service, idx) => {
                let servicePanel = null;
                switch (service.type) {
                    case mafServiceTypes.BROKEN_GLASS:
                    case mafServiceTypes.MARTEN_BITE:
                    case mafServiceTypes.ROADKILL: {
                        servicePanel = (
                            <MIAFormAidServicePanel
                                service={service}
                                isDefaultExpanded={isDefaultExpanded}
                            />
                        );
                        break;
                    }
                    case mafServiceTypes.FURTHER_JOURNEY:
                    case mafServiceTypes.SHORT_TRIP: {
                        servicePanel = (
                            <MIAFormFurtherJourneyServicePanel
                                service={service}
                                isDefaultExpanded={isDefaultExpanded}
                            />
                        );
                        break;
                    }
                    case mafServiceTypes.RENTAL_CAR: {
                        servicePanel = (
                            <MIAFormRentalCarServicePanel
                                service={service}
                                isDefaultExpanded={isDefaultExpanded}
                            />
                        );
                        break;
                    }
                    case mafServiceTypes.ACCOMMODATION: {
                        servicePanel = (
                            <MIAFormAccommodationServicePanel
                                service={service}
                                isDefaultExpanded={isDefaultExpanded}
                            />
                        );
                        break;
                    }
                    default: {
                        servicePanel = (
                            <MIAFormServicePanel
                                service={service}
                                isDefaultExpanded={isDefaultExpanded}
                            />
                        );
                    }
                }
                return (
                    <ContentBlock
                        key={`${idx}-${service.type}`}
                        className={cx('global!ace-u-flex', 'global!ace-u-flex--direction-column')}
                    >
                        <ContentItem>
                            {servicePanel}
                        </ContentItem>
                    </ContentBlock>
                );
            })}
        </Fragment>
    );
};

MIAFormTab.propTypes = {
    invoiceSubmission: PropTypes.object,
};

MIAFormTab.defaultProps = {
    invoiceSubmission: null,
};

const mapStateToProps = state => {
    return {
        invoiceSubmission: state.invoices.invoiceSubmission,
    };
};

export default connect(mapStateToProps, null)(MIAFormTab);
