import {renderRoutes} from '@computerrock/formation-router';
import routePaths from './routePaths';
import keycloakAuth from './keycloakAuth';
import arcGISAuth from './arcGISAuth';
import leaAccessControl from './leaAccessControl';
// Loader sagas
import * as userProfileLoaderSagas from './user-profiles/userProfileLoaderSagas';
import * as applicationLoaderSagas from './application/applicationLoaderSagas';
import * as serviceCaseLoaderSagas from './service-cases/serviceCaseLoaderSagas';
import * as invoiceLoaderSagas from './invoices/invoiceLoaderSagas';
import * as memberLoaderSagas from './members/memberLoaderSagas';
import * as caseLogLoaderSagas from './case-logs/caseLogLoaderSagas';
// Screens
// Application domain
import ApplicationScreen from './application/ApplicationScreen';
import NotFoundScreen from './application/NotFoundScreen';
import AuthRedirectionScreen from './application/AuthRedirectionScreen';
import AccessDeniedScreen from './application/AccessDeniedScreen';
// Dashboard domain
import DashboardScreen from './dashboard/DashboardScreen';
import DashboardSectionScreen from './dashboard/DashboardSectionScreen';
import * as dashboardLoaderSagas from './dashboard/dashboardLoaderSagas';
// Service case domain
import ServiceCaseScreen from './service-cases/ServiceCaseScreen';
import ServiceCaseOverviewScreen from './service-cases/ServiceCaseOverviewScreen';
import ServiceCaseSearchScreen from './service-cases/ServiceCaseSearchScreen';
import InvoiceScreen from './invoices/InvoiceScreen';
import InvoiceOverviewScreen from './invoices/InvoiceOverviewScreen';
import InvoicesSearchScreen from './invoices/InvoicesSearchScreen';
// Member domain
import MemberScreen from './members/MemberScreen';
import MemberSearchScreen from './members/MemberSearchScreen';
import ContactsSearchScreen from './contacts/ContactsSearchScreen';
import * as contactLoaderSagas from './contacts/contactLoaderSagas';
// Communication domain
import * as communicationLoaderSagas from './communication/communicationLoaderSagas';
// Task domain
import * as taskLoaderSagas from './tasks/taskLoaderSagas';
import TaskSearchScreen from './tasks/TaskSearchScreen';

/**
 * Location object definition (react-router, history)
 *
 * @typedef {Object} Location
 * @property {string} key - key is available for all by HashHistory
 * @property {string} pathname - path part of route
 * @property {string} search - search query part of route
 * @property {string} hash - hash (#) part of route
 * @property {Object} state - user defined state for the route
 */

/**
 * ConnectedRoute object definition (react-router-config, connected-react-router, redux-saga)
 *
 * @typedef {Object} ConnectedRoute
 * @property {string} path - any valid URL path that path-to-regexp understands.
 * @property {?Object} component - React component for application screen
 * @property {?function} render - render prop function
 * @property {?Location} location - for matching against different location than one in history
 * @property {?boolean} exact - when true, will only match if the path matches the location.pathname exactly
 * @property {?boolean} string - when true, a path that has a trailing slash will only match a location.pathname
 *                      with a trailing slash
 * @property {?boolean} sensitive - when true, will match if the path is case sensitive.
 * @property {?Array<ConnectedRoute>} routes - sub-routes
 * @property {?Array<Array<[saga, Object]>>} locationChangeSideEffects - Redux sagas (side-effects) to be run
 *                                            with payload object
 */

/**
 * Static route configuration
 *
 * @returns {Array<ConnectedRoute>}
 */
const getRoutes = () => [{
    component: ApplicationScreen,
    locationChangeSideEffects: [],
    routes: [
        // TODO: implement MaintenancePage logic
        {
            path: routePaths.KEYCLOAK_AUTHORIZE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [keycloakAuth.authorize],
            ],
        },
        {
            path: routePaths.KEYCLOAK_AUTHENTICATE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [keycloakAuth.authenticate],
            ],
        },
        {
            path: routePaths.ARCGIS_AUTHORIZE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [arcGISAuth.authorize],
            ],
        },
        {
            path: routePaths.ARCGIS_AUTHENTICATE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [arcGISAuth.authenticate],
            ],
        },
        {
            path: routePaths.SYSTEM_ACCESS_DENIED,
            exact: true,
            component: AccessDeniedScreen,
            locationChangeSideEffects: [],
        },
        {
            component: ({route}) => renderRoutes(route.routes),
            locationChangeSideEffects: [
                [applicationLoaderSagas.ensureRoute],
                [keycloakAuth.loadAuthSession],
                [userProfileLoaderSagas.loadUserProfile],
                [leaAccessControl.authenticateSystemAccess],
                [leaAccessControl.authenticateRouteAccess],
                [arcGISAuth.loadAuthSession],
                [userProfileLoaderSagas.loadUserProfiles],
                [communicationLoaderSagas.loadCommunicationSenderAccounts],
            ],
            routes: [
                {
                    path: routePaths.ROOT,
                    exact: true,
                    component: () => null,
                    locationChangeSideEffects: [],
                },
                {
                    path: routePaths.ROUTE_ACCESS_DENIED,
                    exact: true,
                    component: AccessDeniedScreen,
                    locationChangeSideEffects: [],
                },
                {
                    path: routePaths.DASHBOARD_SECTION,
                    component: DashboardSectionScreen,
                    locationChangeSideEffects: [
                        [communicationLoaderSagas.loadEllaUnreadMessages],
                    ],
                    routes: [
                        {
                            path: routePaths.DASHBOARD,
                            exact: true,
                            component: DashboardScreen,
                            locationChangeSideEffects: [
                                [dashboardLoaderSagas.loadCurrentUsersTasks],
                                [dashboardLoaderSagas.loadReporterTasksAmount],
                                [dashboardLoaderSagas.loadReporterInvoicesAmount],
                            ],
                        },
                        {
                            path: routePaths.MEMBER_SEARCH,
                            exact: true,
                            component: MemberSearchScreen,
                            locationChangeSideEffects: [
                                [memberLoaderSagas.loadMemberSearchResults],
                            ],
                        },
                        {
                            path: routePaths.INVOICES_SEARCH,
                            component: InvoicesSearchScreen,
                            locationChangeSideEffects: [
                                [invoiceLoaderSagas.loadInvoiceSearchResults],
                                [serviceCaseLoaderSagas.loadServiceCaseSearchResults],
                            ],
                        },
                        {
                            path: routePaths.CONTACTS,
                            exact: true,
                            component: ContactsSearchScreen,
                            locationChangeSideEffects: [
                                [contactLoaderSagas.loadContactSearchResults],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_CASES_SEARCH,
                            exact: true,
                            component: ServiceCaseSearchScreen,
                            locationChangeSideEffects: [
                                [serviceCaseLoaderSagas.loadServiceCaseSearchResults],
                            ],
                        },
                        {
                            path: routePaths.TASKS_SEARCH,
                            exact: true,
                            component: TaskSearchScreen,
                            locationChangeSideEffects: [
                                [taskLoaderSagas.loadTaskSearchResults],
                                [userProfileLoaderSagas.loadLeaUserProfiles],
                            ],
                        },
                    ],
                },
                {
                    path: routePaths.SERVICE_CASE_SECTION,
                    component: ({route}) => renderRoutes(route.routes),
                    locationChangeSideEffects: [
                        [serviceCaseLoaderSagas.loadServiceCase],
                        [invoiceLoaderSagas.loadServices],
                        [serviceCaseLoaderSagas.loadServiceCaseInvoices],
                        [serviceCaseLoaderSagas.loadServiceCaseDocuments],
                    ],
                    routes: [
                        {
                            path: routePaths.SERVICE_CASE,
                            exact: true,
                            component: ServiceCaseScreen,
                            locationChangeSideEffects: [
                                [serviceCaseLoaderSagas.loadMemberVehicles],
                                [serviceCaseLoaderSagas.loadServiceCaseVehicleDetails],
                            ],
                        },
                        {
                            path: routePaths.INVOICES,
                            exact: true,
                            component: InvoiceScreen,
                            locationChangeSideEffects: [
                                [invoiceLoaderSagas.loadInvoice],
                            ],
                        },
                        {
                            path: routePaths.INVOICE_OVERVIEW,
                            exact: true,
                            component: InvoiceOverviewScreen,
                            locationChangeSideEffects: [
                                [invoiceLoaderSagas.loadInvoice],
                                [invoiceLoaderSagas.loadInvoiceSubmission],
                                [invoiceLoaderSagas.loadReadOnlyManualCheckInformation],
                                [invoiceLoaderSagas.loadFixedPriceInformation],
                                [invoiceLoaderSagas.loadInvoiceOverviewDetails],
                                [invoiceLoaderSagas.loadInvoiceRelevantLocationsMapLayers],
                                [invoiceLoaderSagas.loadInvoiceRelevantLocations],
                                [caseLogLoaderSagas.loadCaseLogs],
                                [caseLogLoaderSagas.loadCaseLogServices],
                                [userProfileLoaderSagas.loadLeaUserProfiles],
                                [taskLoaderSagas.loadTaskSearchResults],
                                [serviceCaseLoaderSagas.loadServiceCaseTasks],
                                [communicationLoaderSagas.loadMessages],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_CASES_OVERVIEW,
                            exact: true,
                            component: ServiceCaseOverviewScreen,
                            locationChangeSideEffects: [
                                [caseLogLoaderSagas.loadCaseLogs],
                                [communicationLoaderSagas.loadMessages],
                                [serviceCaseLoaderSagas.loadServiceCaseTasks],
                                [userProfileLoaderSagas.loadLeaUserProfiles],
                                [taskLoaderSagas.loadTaskSearchResults],
                            ],
                        },
                    ],
                },
                {
                    path: routePaths.MEMBER,
                    component: MemberScreen,
                    locationChangeSideEffects: [
                        [memberLoaderSagas.loadMemberDetails],
                        [memberLoaderSagas.loadMemberServiceCases],
                        [memberLoaderSagas.loadMemberBCDetails],
                        [memberLoaderSagas.loadMemberMessages],
                    ],
                },
                {
                    path: '*',
                    component: NotFoundScreen,
                },
            ],
        },
    ],
}];

export default getRoutes;
