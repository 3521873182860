import React, {Fragment, useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useStyles, ContentBlock, ContentItem, AppLayoutMain, HeaderBar, Panel} from '@ace-de/ui-components';
import {InputField, SelectField, Option, DateField} from '@ace-de/ui-components/form';
import {Icon, InteractiveIcon, communicationIcon, deleteIcon, rightIcon, calendarIcon, plusIcon, editIcon} from '@ace-de/ui-components/icons';
import {Button, ButtonIcon} from '@ace-de/ui-components/buttons';
import {alfAccountTypes, alfInvoiceChannelTypes, alfClientTypes, alfInvoiceStatusTypes, alfInvoiceTypes, Invoice, persistenceStates} from '@ace-de/eua-entity-types'; // eslint-disable-line max-len
import {useTranslate} from '@computerrock/formation-i18n';
import InvoiceHeader from './ui-elements/InvoiceHeader';
import ServiceOverviewPanel from './ui-elements/ServiceOverviewPanel';
import InvoiceDocumentUploadPanel from './ui-elements/InvoiceDocumentUploadPanel';
import * as invoiceSelectors from './invoiceSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as invoiceActionTypes from './invoiceActionTypes';
import * as contactActionTypes from '../contacts/contactActionTypes';
import * as communicationActionTypes from '../communication/communicationActionTypes';
import serviceManager from '../serviceManager';
import useBeforeUnloadEffect from '../useBeforeUnloadEffect';
import {leaFeatures, leaFeatureActions} from '../application/leaFeatures';
import leaAccessControl from '../leaAccessControl';

const InvoiceScreen = props => {
    const {cx} = useStyles();
    const {invoice, submitInvoiceBasicDataForm, initiateInvoiceContactSelectionFlow, serviceCase} = props;
    const {location, match, initiateInvoiceScreenRedirection, initiateEditAccountPartyFlow} = props;
    const {initiateSendMessageFlow, initiateManualInvoiceStatusUpdateFlow} = props;
    const {accountPartyOptions, fetchAccountParty, accountParty} = props;
    const {initiateInvoiceDeleteFlow} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('create_invoice_screen');
    const [isBaseFormTouched, setIsBaseFormTouched] = useState(false);
    const assignedAccountParty = accountPartyOptions.find(accountPartyOption => (
        accountPartyOption.id === invoice?.accountParty?.id
    ));
    const [basicFormData, setBasicFormData] = useState({
        accountPartyId: assignedAccountParty?.id,
        accountParty: assignedAccountParty?.id === invoice?.accountParty?.id
            // use bankData from account party on invoice if it is the same as assignedAccountParty
            ? invoice?.accountParty
            : assignedAccountParty,
        externalInvoiceNumber: invoice?.externalInvoiceNumber,
        receiptDate: invoice?.receiptDate,
        bookingDate: invoice?.bookingDate,
        isVATIncluded: invoice?.isVATIncluded,
        additionalInformation: invoice?.additionalInformation,
    });
    const [maxBookingDateError, setMaxBookingDateError] = useState('');
    const [maxReceiptDateError, setMaxReceiptDateError] = useState('');
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    const isInvoiceDeletionAllowed = leaAccessControl.grantFeatureAccess(
        leaFeatures.INVOICE,
        leaFeatureActions.DELETE,
    );

    const isMessagingAllowed = leaAccessControl.grantFeatureAccess(
        leaFeatures.MESSAGE,
        leaFeatureActions.CREATE,
    );

    const handleOnSubmit = () => {
        if (!basicFormData) return;

        submitInvoiceBasicDataForm({
            invoiceId: invoice.id,
            invoiceData: basicFormData,
            triggerINACheck: true,
        });
    };

    const initiateMissingContactSearch = value => {
        initiateInvoiceContactSelectionFlow({
            initiateContactSearch: true,
            invoiceId: invoice.id,
            client: serviceCase?.client,
            contactId: value,
        });
    };

    const handleOnChange = (formField, value) => {
        if (formField === 'accountPartyId' && value === 'add-account-party') {
            initiateInvoiceContactSelectionFlow({invoiceId: invoice.id, client: serviceCase?.client});
            return;
        }
        if (formField === 'accountPartyId' && value.includes('missing-')) {
            initiateMissingContactSearch(value.replace('missing-', ''));
        }
        if (formField === 'accountPartyId' && !value.includes('missing-')) {
            // on accountParty selection from dropdown we need to show bank data information
            // in order to do so, we need to fetch account party data from another endpoint
            fetchAccountParty({
                accountPartyId: value,
                accountPartyType: invoice.type === alfInvoiceTypes.EKR
                    ? alfAccountTypes.CREDITOR
                    : alfAccountTypes.DEBTOR,
                client: serviceCase.client,
            });
        }
        setBasicFormData(prevState => ({
            ...prevState,
            ...(formField === 'accountPartyId' && {
                accountParty: accountPartyOptions.find(accountPartyOption => (
                    accountPartyOption.id === value
                )),
            }),
            [formField]: value,
        }));
        setIsBaseFormTouched(true);
    };

    const handleOnTabChange = useCallback(() => {
        if (!invoice?.id || !basicFormData || !isBaseFormTouched) return;

        submitInvoiceBasicDataForm({
            invoiceId: invoice.id,
            invoiceData: basicFormData,
            triggerINACheck: false,
        });
    }, [submitInvoiceBasicDataForm, invoice, basicFormData, isBaseFormTouched]);

    useEffect(() => {
        if (invoice?.persistenceState === persistenceStates.READY) {
            setBasicFormData({
                accountParty: assignedAccountParty?.id === invoice?.accountParty?.id
                    ? invoice?.accountParty
                    : assignedAccountParty,
                accountPartyId: assignedAccountParty?.id,
                externalInvoiceNumber: invoice?.externalInvoiceNumber,
                receiptDate: invoice?.receiptDate,
                bookingDate: invoice?.bookingDate,
                isVATIncluded: invoice?.isVATIncluded,
                additionalInformation: invoice?.additionalInformation,
            });
        }
    }, [invoice, assignedAccountParty]);

    useEffect(() => {
        initiateInvoiceScreenRedirection({
            match,
            location,
        });
    }, [initiateInvoiceScreenRedirection, match, location]);

    useEffect(() => {
        if (basicFormData.bookingDate) setMaxBookingDateError(moment(basicFormData.bookingDate).isSameOrAfter()
            ? translateScreen('error_message.not_allowed_date')
            : '');
        if (basicFormData.receiptDate) setMaxReceiptDateError(moment(basicFormData.receiptDate).isSameOrAfter()
            ? translateScreen('error_message.not_allowed_date')
            : '');
    }, [basicFormData, translateScreen]);

    useRouteUnmountEffect(() => {
        handleOnTabChange();
    }, [handleOnTabChange]);

    useBeforeUnloadEffect(event => {
        if (!invoice?.id || !basicFormData || !isBaseFormTouched) return;
        return {
            request: leaAssignmentFlowService.updateInvoice,
            requestData: {
                invoiceId: invoice.id,
                invoicePatchDTO: Invoice.objectToPatchDTO(basicFormData),
            },
        };
    });

    const approveVKRInvoice = () => {
        initiateManualInvoiceStatusUpdateFlow({
            invoiceId: invoice.id,
            serviceCaseId: invoice.serviceCaseId,
            invoiceStatusData: {
                status: alfInvoiceStatusTypes.APPROVED,
            },
            isVKRInvoiceCreation: true,
            ...(!!basicFormData && {invoiceData: basicFormData}),
        });
    };

    if (!invoice) return null;

    const isButtonDisabled = (invoice.type !== alfInvoiceTypes.VKR && !basicFormData.externalInvoiceNumber)
        || !basicFormData.receiptDate || !basicFormData.bookingDate
        || (basicFormData.bookingDate && moment(basicFormData.bookingDate).isSameOrAfter() && !!maxBookingDateError)
        || (basicFormData.receiptDate && !!maxReceiptDateError)
        || !basicFormData.accountPartyId || !invoice.lines.length > 0;

    const isInvoiceDeletionDisabled = invoice.channel !== alfInvoiceChannelTypes.LEA
        || !isInvoiceDeletionAllowed;

    return (
        <Fragment>
            <HeaderBar className={cx('ace-c-header-bar--primary-navigation')}>
                <InvoiceHeader invoice={invoice} />
            </HeaderBar>
            <AppLayoutMain>
                <ContentBlock>
                    <ContentItem>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-space-between',
                            ])}
                        >
                            <div>
                                <Button
                                    type="button"
                                    name="deleteInvoice"
                                    onClick={() => {
                                        initiateInvoiceDeleteFlow({invoiceId: invoice.id});
                                    }}
                                    isDisabled={isInvoiceDeletionDisabled}
                                >
                                    <Icon
                                        icon={deleteIcon}
                                        className={cx([
                                            'global!ace-u-margin--right-8',
                                            {
                                                'ace-c-icon--color-disabled': isInvoiceDeletionDisabled,
                                                'ace-c-icon--color-highlight': !isInvoiceDeletionDisabled,
                                            },
                                        ])}
                                    />
                                    {translateScreen('button_label.delete_invoice')}
                                </Button>
                                <Button
                                    className={cx('global!ace-u-margin--left-8')}
                                    type="button"
                                    name="startCommunication"
                                    onClick={() => initiateSendMessageFlow({serviceCaseId: serviceCase.id})}
                                    isDisabled={!isMessagingAllowed}
                                >
                                    <Icon
                                        icon={communicationIcon}
                                        className={cx([
                                            'global!ace-u-margin--right-8',
                                            'ace-c-icon--color-highlight',
                                        ])}
                                    />
                                    {translateScreen('button_label.communication')}
                                </Button>
                            </div>
                            <ButtonIcon
                                name={invoice.type === alfInvoiceTypes.VKR ? 'create' : 'startINA'}
                                className={cx('global!ace-u-flex--align-self-flex-end')}
                                isDisabled={isButtonDisabled}
                                onClick={invoice.type === alfInvoiceTypes.VKR ? approveVKRInvoice : handleOnSubmit}
                                icon={rightIcon}
                            >
                                {invoice.type === alfInvoiceTypes.VKR
                                    ? translateScreen('button_label.create')
                                    : translateScreen('button_label.start_ina')}
                            </ButtonIcon>
                        </div>
                    </ContentItem>
                </ContentBlock>
                <ContentBlock
                    className={cx([
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                        'global!ace-u-flex--align-stretch',
                    ])}
                >
                    <ContentItem className={cx('ace-c-content-item--span-4')}>
                        <Panel
                            title={translateScreen('basic_data_panel.title')}
                            className={cx('global!ace-u-height--750')}
                        >
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-flex--justify-space-between',
                                ])}
                            >
                                <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-32')}>
                                    <SelectField
                                        name="accountPartyId"
                                        className={cx('global!ace-u-grid-column--span-6')}
                                        label={`${translateScreen(`basic_data_panel_select_label.${invoice.type === alfInvoiceTypes.VKR ? 'debtor' : 'creditor'}`)}*`}
                                        placeholder={translateScreen('basic_data_panel_select_placeholder.please_select')}
                                        value={basicFormData.accountPartyId || ''}
                                        onChange={value => { handleOnChange('accountPartyId', value); }}
                                    >
                                        {accountPartyOptions.map(creditor => {
                                            const {id, name, accountPartyType} = creditor;
                                            if (accountPartyType) {
                                                return (
                                                    <Option
                                                        key={id}
                                                        value={id}
                                                        name={`${name}Option`}
                                                    >
                                                        {name}
                                                    </Option>
                                                );
                                            }
                                            return (
                                                <Option
                                                    key={id}
                                                    value={`missing-${id}`}
                                                    name={`${id}Option`}
                                                >
                                                    <Icon
                                                        icon={plusIcon}
                                                        className={cx([
                                                            'global!ace-u-margin--right-8',
                                                            'ace-c-icon--color-highlight-light',
                                                        ])}
                                                    />
                                                    <span
                                                        className={cx('global!ace-u-typography--color-highlighted-hover')}
                                                    >
                                                        {id}
                                                    </span>
                                                </Option>
                                            );
                                        })}
                                        <Option
                                            value="add-account-party"
                                            name="addAccountPartyOption"
                                        >
                                            <Icon
                                                icon={plusIcon}
                                                className={cx([
                                                    'global!ace-u-margin--right-8',
                                                    'ace-c-icon--color-highlight-light',
                                                ])}
                                            />
                                            <span className={cx('global!ace-u-typography--color-highlighted-hover')}>
                                                {translateScreen(`basic_data_panel_option_label.add_${invoice.type === alfInvoiceTypes.VKR ? 'debtor' : 'creditor'}`)}
                                            </span>
                                        </Option>
                                    </SelectField>
                                    <InputField
                                        name="externalInvoiceNumber"
                                        className={cx('global!ace-u-grid-column--span-6')}
                                        label={`${translateScreen('basic_data_panel_input_label.external_invoice_no')}${invoice.type !== alfInvoiceTypes.VKR ? ' *' : ''}`}
                                        placeholder={translateScreen('basic_data_panel_input_placeholder.please_enter')}
                                        value={basicFormData.externalInvoiceNumber || ''}
                                        onChange={value => handleOnChange('externalInvoiceNumber', value)}
                                    />
                                </div>
                                <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-32')}>
                                    <div className={cx('global!ace-u-grid-column--span-12')}>
                                        <p
                                            className={cx([
                                                'global!ace-u-typography--variant-caption',
                                                'global!ace-u-margin--bottom-4',
                                            ])}
                                        >
                                            {translateScreen('basic_data_panel_text_label.bank_information')}
                                        </p>
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-flex--justify-space-between',
                                            ])}
                                        >
                                            <p
                                                className={cx([
                                                    'global!ace-u-padding--8-16',
                                                    'global!ace-u-typography--variant-body', {
                                                        'global!ace-u-typography--color-disabled': !basicFormData.accountParty,
                                                    },
                                                ])}
                                            >
                                                {basicFormData.accountParty
                                                    ? basicFormData.accountParty.bankAccount
                                                        ? `${basicFormData.accountParty.bankAccount?.iban || '-'} | ${basicFormData.accountParty.bankAccount?.swiftCode || '-'} | ${basicFormData.accountParty.bankAccount?.name || '-'}`
                                                        : `${accountParty.bankAccount?.iban || '-'} | ${accountParty.bankAccount?.swiftCode || '-'} | ${accountParty.bankAccount?.name || '-'}`
                                                    : translateScreen('basic_data_panel_text_placeholder.bank_information')}
                                            </p>
                                            {basicFormData.accountPartyId && (
                                                <InteractiveIcon
                                                    icon={editIcon}
                                                    onClick={() => initiateEditAccountPartyFlow({
                                                        accountPartyId: basicFormData.accountPartyId,
                                                        client: serviceCase?.client,
                                                        accountPartyType: basicFormData.accountParty.accountPartyType
                                                        === alfAccountTypes.CREDITOR
                                                        || invoice.type === alfInvoiceTypes.EKR
                                                            ? alfAccountTypes.CREDITOR
                                                            : alfAccountTypes.DEBTOR,
                                                    })}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-32')}>
                                    <DateField
                                        name="receiptDate"
                                        label={`${translateScreen('basic_data_panel_date_input_label.receipt_date')}*`}
                                        className={cx('global!ace-u-grid-column--span-6')}
                                        icon={calendarIcon}
                                        placeholder={translateScreen('basic_data_panel_date_input_placeholder.please_select_date')}
                                        value={basicFormData.receiptDate || ''}
                                        onChange={value => handleOnChange('receiptDate', value)}
                                        maxDate={moment().format()}
                                        errors={maxReceiptDateError ? [maxReceiptDateError] : []}
                                    />
                                    <DateField
                                        name="bookingDate"
                                        label={`${translateScreen('basic_data_panel_date_input_label.booking_date')}*`}
                                        className={cx('global!ace-u-grid-column--span-6')}
                                        icon={calendarIcon}
                                        placeholder={translateScreen('basic_data_panel_date_input_placeholder.please_select_date')}
                                        value={basicFormData.bookingDate || ''}
                                        onChange={value => handleOnChange('bookingDate', value)}
                                        maxDate={moment().format()}
                                        errors={maxBookingDateError ? [maxBookingDateError] : []}
                                    />
                                </div>
                                <div className={cx('global!ace-u-grid')}>
                                    <SelectField
                                        label={`${translateScreen('basic_data_panel_select_label.gross_net')}*`}
                                        name="isVATIncluded"
                                        className={cx('global!ace-u-grid-column--span-6', 'global!ace-u-margin--bottom-64')}
                                        placeholder={translateScreen('basic_data_panel_select_placeholder.please_select')}
                                        value={typeof basicFormData.isVATIncluded === 'boolean' ? basicFormData.isVATIncluded : ''}
                                        onChange={value => handleOnChange('isVATIncluded', value)}
                                    >
                                        <Option
                                            name="isTaxIncludedTrue"
                                            value={true}
                                        >
                                            {translate('global.invoice_amount_types.gross')}
                                        </Option>
                                        <Option
                                            name="isTaxIncludedFalse"
                                            value={false}
                                        >
                                            {translate('global.invoice_amount_types.net_income')}
                                        </Option>
                                    </SelectField>
                                </div>
                                <div className={cx('global!ace-u-grid')}>
                                    {invoice.type === alfInvoiceTypes.VKR
                                    && serviceCase.client === alfClientTypes.WACE && (
                                        <InputField
                                            name="additionalInformation"
                                            className={cx('global!ace-u-grid-column--span-12')}
                                            label={translateScreen('basic_data_panel_input_label.additional_information_for_document_creation')}
                                            placeholder={translateScreen('basic_data_panel_input_placeholder.please_enter')}
                                            value={basicFormData.additionalInformation || ''}
                                            onChange={value => handleOnChange('additionalInformation', value)}
                                        />
                                    )}
                                </div>
                            </div>
                        </Panel>
                    </ContentItem>
                    <ContentItem className={cx('ace-c-content-item--span-4')}>
                        <ServiceOverviewPanel invoiceData={basicFormData} isBaseFormTouched={isBaseFormTouched} />
                    </ContentItem>
                    <ContentItem className={cx('ace-c-content-item--span-4')}>
                        <InvoiceDocumentUploadPanel />
                    </ContentItem>
                </ContentBlock>
            </AppLayoutMain>
        </Fragment>
    );
};

InvoiceScreen.propTypes = {
    invoice: PropTypes.object,
    serviceCase: PropTypes.object,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    submitInvoiceBasicDataForm: PropTypes.func.isRequired,
    initiateInvoiceContactSelectionFlow: PropTypes.func.isRequired,
    initiateInvoiceScreenRedirection: PropTypes.func.isRequired,
    initiateEditAccountPartyFlow: PropTypes.func.isRequired,
    initiateSendMessageFlow: PropTypes.func.isRequired,
    initiateManualInvoiceStatusUpdateFlow: PropTypes.func.isRequired,
    fetchAccountParty: PropTypes.func.isRequired,
    accountPartyOptions: PropTypes.array,
    accountParty: PropTypes.object,
    initiateInvoiceDeleteFlow: PropTypes.func.isRequired,
};

InvoiceScreen.defaultProps = {
    invoice: null,
    serviceCase: null,
    accountPartyOptions: [],
    accountParty: null,
};

const mapStateToProps = (state, props) => {
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();

    return {
        invoice: getInvoice(state, props),
        serviceCase: getServiceCase(state, props),
        accountPartyOptions: state.invoices.accountPartyOptions,
        accountParty: state.contacts.accountParty,
    };
};

const mapDispatchToProps = dispatch => ({
    submitInvoiceBasicDataForm: payload => dispatch({
        type: invoiceActionTypes.SUBMIT_INVOICE_BASIC_DATA_FORM,
        payload,
    }),
    initiateInvoiceContactSelectionFlow: payload => dispatch({
        type: invoiceActionTypes.INITIATE_CONTACT_SELECTION_FLOW,
        payload,
    }),
    initiateInvoiceScreenRedirection: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_SCREEN_REDIRECTION,
        payload,
    }),
    initiateEditAccountPartyFlow: payload => dispatch({
        type: contactActionTypes.INITIATE_EDIT_ACCOUNT_PARTY_FLOW,
        payload,
    }),
    initiateSendMessageFlow: payload => dispatch({
        type: communicationActionTypes.INITIATE_SEND_MESSAGE_FLOW,
        payload,
    }),
    initiateManualInvoiceStatusUpdateFlow: payload => dispatch({
        type: invoiceActionTypes.INITIATE_MANUAL_INVOICE_STATUS_UPDATE_FLOW,
        payload,
    }),
    fetchAccountParty: payload => dispatch({
        type: contactActionTypes.FETCH_ACCOUNT_PARTY,
        payload,
    }),
    initiateInvoiceDeleteFlow: payload => dispatch({
        type: invoiceActionTypes.INITIATE_INVOICE_DELETE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceScreen));
