/**
 * App config
 */
export default {
    // General
    APP_VERSION: process.env.APP_VERSION,
    APP_BUILD: process.env.APP_BUILD,
    PUBLIC_URL: process.env.PUBLIC_URL || '/',
    LOCALE: process.env.LOCALE || 'en',
    DEPLOYMENT_ENV: process.env.DEPLOYMENT_ENV,
    // Auth
    COOKIE_DOMAIN: process.env.COOKIE_DOMAIN || undefined,
    ACE_KEYCLOAK_SERVICE_URL: process.env.ACE_KEYCLOAK_SERVICE_URL,
    ACE_KEYCLOAK_REALM: process.env.ACE_KEYCLOAK_REALM,
    ACE_KEYCLOAK_CLIENT_ID: process.env.ACE_KEYCLOAK_CLIENT_ID,
    // Template for ArcGIS
    ARCGIS_OAUTH2_SERVICE_URL: process.env.ARCGIS_OAUTH2_SERVICE_URL,
    ARCGIS_OAUTH2_CLIENT_ID: process.env.ARCGIS_OAUTH2_CLIENT_ID,
    ARCGIS_SERVICE_URL: process.env.ARCGIS_SERVICE_URL,
    TEMP_ARCGIS_MAP_ID: process.env.TEMP_ARCGIS_MAP_ID,
    ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL: process.env.ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL || '',
    ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL: process.env.ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL || '',
    ARCGIS_RENTAL_CAR_STATIONS_LAYER_URL: process.env.ARCGIS_RENTAL_CAR_STATIONS_LAYER_URL || '',
    // Locale
    ACE_LOCALE_TRANSLATION_EN_US_URL: process.env.ACE_LOCALE_TRANSLATION_EN_US_URL,
    ACE_LOCALE_TRANSLATION_DE_DE_URL: process.env.ACE_LOCALE_TRANSLATION_DE_DE_URL,
    // Microservices
    ACE_MEMBER_PROFILE_AMP_SERVICE_URL: process.env.ACE_MEMBER_PROFILE_AMP_SERVICE_URL || '',
    ACE_USER_PROFILE_EUP_SERVICE_URL: process.env.ACE_USER_PROFILE_EUP_SERVICE_URL || '',
    ACE_LEA_ASSIGNMENT_FLOW_ALF_SERVICE_URL: process.env.ACE_LEA_ASSIGNMENT_FLOW_ALF_SERVICE_URL || '',
    ACE_ALF_FILE_ASSETS_SERVICE_ENDPOINT: process.env.ACE_ALF_FILE_ASSETS_SERVICE_ENDPOINT || '',
    ACE_ECS_HISTORY_MANAGEMENT_EHM_SERVICE_URL: process.env.ACE_ECS_HISTORY_MANAGEMENT_EHM_SERVICE_URL || '',
    ACE_ECS_TASK_MANAGEMENT_ETM_SERVICE_URL: process.env.ACE_ECS_TASK_MANAGEMENT_ETM_SERVICE_URL || '',
    ACE_PRICING_MANAGEMENT_APS_SERVICE_URL: process.env.ACE_PRICING_MANAGEMENT_APS_SERVICE_URL || '',
    ACE_MEMBER_ASSIGNMENT_FLOW_SERVICE_URL: process.env.ACE_MEMBER_ASSIGNMENT_FLOW_SERVICE_URL || '',
    DATAFACTORY_SERVICE_URL: process.env.DATAFACTORY_SERVICE_URL || '',
    DATAFACTORY_AUTH_SERVICE_URL: process.env.DATAFACTORY_AUTH_SERVICE_URL || '',
    // Datadog
    DATADOG_RUM_APPLICATION_ID: process.env.DATADOG_RUM_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE: process.env.DATADOG_SERVICE,
    DATADOG_ENABLE: process.env.DATADOG_ENABLE === 'true',
    // SilverDAT
    SILVER_DAT_SERVICE_URL: process.env.SILVER_DAT_SERVICE_URL || '',
    SILVER_DAT_AUTH_SERVICE_URL: process.env.SILVER_DAT_AUTH_SERVICE_URL || '',
    // App constants
    MINIMUM_SEARCH_QUERY_LENGTH: 3,
    DEFAULT_PAGE_SIZE: 10,
    TASKS_DEFAULT_PAGE_SIZE: 5,
    ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT: 10,
    ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER: 450,
    MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES: 52428800,
    SERVICE_CODE_MAX_LENGTH: 4,
    CURRENCY: process.env.CURRENCY || 'EUR',
    MAXIMUM_INVOICE_SIZE_COUNT: 50,
    GERMANY_CENTER_COORDINATES: [10.451500, 51.165700],
    MAXIMUM_TEXT_AREA_CONTENT_LENGTH: 250,
    MEMBER_DUNNING_LEVEL_LIMIT: 1,
    PROTOCOL_DEFAULT_PAGE_SIZE: 500,
    MESSAGE_MAX_LENGTH: 2500,
    TASK_DESCRIPTION_MAX_LENGTH: 2500,
    LEA_OUTBOX_ADDRESS_LEIST: process.env.LEA_OUTBOX_ADDRESS_LEIST,
    LEA_OUTBOX_ADDRESS_ONLINE_BILLING: process.env.LEA_OUTBOX_ADDRESS_ONLINE_BILLING,
    LEA_OUTBOX_ADDRESS_BILLING: process.env.LEA_OUTBOX_ADDRESS_BILLING,
    LEA_OUTBOX_ADDRESS_WACE: process.env.LEA_OUTBOX_ADDRESS_WACE,
    SHOULD_SEND_DOCUMENT_DELETE_REQUEST: false,
    MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT: 90,
    MINIMUM_VEHICLE_BUILD_YEAR: 1920,
    MAX_VEHICLE_WEIGHT: 50,
    MAX_VEHICLE_HEIGHT: 5,
    MAX_VEHICLE_LENGTH: 25,
    MAX_VEHICLE_WIDTH: 4,
    DEFAULT_SPACE_COUNT: 5,
    STATUS_CODE_SERVICE_UNAVAILABLE: 503,
    STATUS_CODE_INTERNAL_SERVER_ERROR: 500,
    STATUS_CODE_BAD_REQUEST: 400,
    UNREAD_ELLA_MESSAGES_SIZE: 1000,
    ADDITIONAL_INFO_CHAR_LIMIT: 50,
};
