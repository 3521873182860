import {alfMessageTextTemplateTypes, alfOutgoingMessageCategoryTypes} from '@ace-de/eua-entity-types';

export default {
    [alfOutgoingMessageCategoryTypes.REJECTION]: [
        alfMessageTextTemplateTypes.AID_TOTAL_LOSS,
        alfMessageTextTemplateTypes.AID_NO_DEDUCTIBLE,
        alfMessageTextTemplateTypes.AID_MEMBER_NOT_OWNER,
        alfMessageTextTemplateTypes.NO_COLLUSION,
        alfMessageTextTemplateTypes.UPON_RECONSIDERATION,
        alfMessageTextTemplateTypes.DRIVER_OR_OWNER,
        alfMessageTextTemplateTypes.TRUCK_REGISTRATION,
        alfMessageTextTemplateTypes.REPAIR,
        alfMessageTextTemplateTypes.ALCOHOL,
        alfMessageTextTemplateTypes.RENTAL_CAR_CLASSIC,
        alfMessageTextTemplateTypes.RENTAL_CAR_COMFORT,
        alfMessageTextTemplateTypes.REGISTRATION_RED_GREEN_NONE,
        alfMessageTextTemplateTypes.DUTY_TRIP,
        alfMessageTextTemplateTypes.NO_AUDITABLE_DOCUMENT,
        alfMessageTextTemplateTypes.CONTRIBUTION_ARREARS,
        alfMessageTextTemplateTypes.STALL_FEES,
        alfMessageTextTemplateTypes.LIMITATION,
        alfMessageTextTemplateTypes.NO_MEMBER,
        alfMessageTextTemplateTypes.ADDITIONAL_PAYMENT,
    ],
    [alfOutgoingMessageCategoryTypes.REQUEST]: [
        alfMessageTextTemplateTypes.REQUEST_EK,
        alfMessageTextTemplateTypes.REQUEST_AID,
        alfMessageTextTemplateTypes.REQUEST_ELLA_OR_ACE_COMMISSIONER,
        alfMessageTextTemplateTypes.REQUEST_GENERAL,
        alfMessageTextTemplateTypes.REMINDER_REQUEST,
    ],
    [alfOutgoingMessageCategoryTypes.REFUND]: [
        alfMessageTextTemplateTypes.ONE_TIME_REIMBURSEMENT,
        alfMessageTextTemplateTypes.ACE_TARIFF,
        alfMessageTextTemplateTypes.RENTAL_CAR,
        alfMessageTextTemplateTypes.ACCOMMODATION,
        alfMessageTextTemplateTypes.ROADSIDE_ASSISTANCE_VEHICLE_OPENING_MAX_120,
        alfMessageTextTemplateTypes.SHORT_TRIP_MAX_50,
        alfMessageTextTemplateTypes.KM_FLAT_RATE,
    ],
    [alfOutgoingMessageCategoryTypes.REPAYMENT]: [
        alfMessageTextTemplateTypes.DIAGNOSTIC_COSTS,
        alfMessageTextTemplateTypes.VEHICLE_RETURN_TOTAL_LOSS,
        alfMessageTextTemplateTypes.FUEL_SHORTAGE,
        alfMessageTextTemplateTypes.OWNER_AND_DRIVER,
        alfMessageTextTemplateTypes.MISSING_DOCUMENTS,
        alfMessageTextTemplateTypes.NO_MEMBERSHIP,
        alfMessageTextTemplateTypes.DUTY_TRIP,
        alfMessageTextTemplateTypes.CONTRIBUTION_ARREARS,
    ],
    [alfOutgoingMessageCategoryTypes.OTHER]: [
        alfMessageTextTemplateTypes.REQUEST_POLICE_OR_PUBLIC_PROSECUTOR_BLOOD_ALCOHOL,
        alfMessageTextTemplateTypes.REGRESS_INSURANCE,
    ],
    [alfOutgoingMessageCategoryTypes.ELLA]: [
        alfMessageTextTemplateTypes.OPERATING_TIME_BEFORE_ASSIGNMENT,
        alfMessageTextTemplateTypes.RECOVERY_DOCUMENTATION,
        alfMessageTextTemplateTypes.BILLING_BY_TIME,
        alfMessageTextTemplateTypes.RETURN_CORRECTION,
        alfMessageTextTemplateTypes.VEHICLE_OPENING_MAX_120,
        alfMessageTextTemplateTypes.REQUEST_SERVICE_REPORT,
        alfMessageTextTemplateTypes.INVOICE_ID_USED_MULTIPLE_TIMES,
        alfMessageTextTemplateTypes.REQUEST_REPAIR_REPORT,
        alfMessageTextTemplateTypes.TONNAGE_SURCHARGE_TOO_HIGH,
        alfMessageTextTemplateTypes.EXPLANATION_OF_OPERATING_TIMES,
        alfMessageTextTemplateTypes.ENTITLEMENT_NEAREST_REPAIR_WORKSHOP,
        alfMessageTextTemplateTypes.INFORMATION_DAMAGE_LOCATION,
        alfMessageTextTemplateTypes.INVOICING_PICKUP,
        alfMessageTextTemplateTypes.EMPTY_TRIP_BY_HOURLY_RATE,
        alfMessageTextTemplateTypes.VEHICLE_OPENING_120_FLAT,
        alfMessageTextTemplateTypes.REJECTION_STALL_FEE,
    ],
};
