import React, {useEffect, useMemo, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ButtonPrimary, downloadIcon, Icon, InteractiveIcon, Panel, outgoingMessageIcon, useStyles, uploadIcon, incomingMessageIcon, Paginator, TableCaption, NoResultsBlock, historyIcon} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {alfInvoiceStatusTypes, alfMessageChannelTypes, alfMessageTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {resolveRoute, withRouter} from '@computerrock/formation-router';
import routePaths from '../routePaths';
import * as invoiceSelectors from '../invoices/invoiceSelectors';
import * as communicationActionTypes from '../communication/communicationActionTypes';
import * as serviceCaseSelectors from './serviceCaseSelectors';
import config from '../config';
import serviceManager from '../serviceManager';
import useBeforeUnloadEffect from '../useBeforeUnloadEffect';
import scOverviewScreenTabs from './scOverviewScreenTabs';
import {leaFeatureActions, leaFeatures} from '../application/leaFeatures';
import leaAccessControl from '../leaAccessControl';

const generateMessagePreview = (messageContent, isFullContent) => {
    if (!messageContent) return;

    if (isFullContent || messageContent.length <= config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT) {
        return messageContent.replaceAll(/<[^>]*>/g, ' ').trim();
    }
    let characters = '';
    [...messageContent].forEach((char, index) => {
        if (index <= config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT - 1) characters += char;
    });
    return `${characters.replaceAll(/<[^>]*>/g, ' ').trim()}... `;
};

const SCCommunicationTab = props => {
    const {cx} = useStyles();
    const {initiateReplyUploadFlow, initiateDownloadAllDocuments, updateMessageAsRead, searchMessages} = props;
    const {history} = props;
    const {serviceCaseInvoices, serviceCaseMessages, serviceCase, caseMessagesPage, caseMessagesTotalCount} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('sc_communication_tab');
    const queryParams = useMemo(() => {
        return new URLSearchParams(history?.location?.search || '');
    }, [history.location.search]);
    const messageId = queryParams?.get('messageId');
    const [messages, setMessages] = useState([]);
    const [readMessageIds, setReadMessageIds] = useState([]);
    const [expandedMessageIds, setExpandedMessageIds] = useState(messageId ? [Number(messageId)] : []);

    const {member} = serviceCase;
    const recipientContactNames = useMemo(() => {
        return member ? {[member.membershipNo]: member.personalDetails?.displayName || ''} : {};
    }, [member]);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const shouldMarkMessageAsRead = useRef(false);
    const isMessageCreationAllowed = leaAccessControl.grantFeatureAccess(
        leaFeatures.MESSAGE,
        leaFeatureActions.CREATE,
    );

    const markMessageAsRead = message => {
        if (!message) return;
        if (!message.isRead && !readMessageIds.includes(message.id)) {
            shouldMarkMessageAsRead.current = true;
            setReadMessageIds(prevState => ([
                ...prevState,
                message.id,
            ]));
        }
    };

    const handleOnMessageContentExpansion = (messageId, event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const newExtendedMessageIdsArray = !expandedMessageIds.includes(messageId)
            ? [...expandedMessageIds, messageId]
            : [...expandedMessageIds].filter(id => messageId !== id);

        setExpandedMessageIds([...newExtendedMessageIdsArray]);

        const openedMessage = serviceCaseMessages.find(message => message.id === Number(messageId));

        if (!openedMessage) return;

        if (!openedMessage.isRead && !readMessageIds.includes(openedMessage.id)) {
            setReadMessageIds(prevState => ([
                ...(prevState.filter(id => id !== openedMessage.id)),
                openedMessage.id,
            ]));
            shouldMarkMessageAsRead.current = true;
        }

        setMessages(prevState => {
            return prevState.map(message => {
                if (message.id === Number(messageId)) return {
                    ...message,
                    messageContentPreview: generateMessagePreview(
                        openedMessage.content,
                        newExtendedMessageIdsArray.includes(messageId),
                    ),
                };
                return message;
            });
        });
    };

    useEffect(() => {
        if (queryParams.get('activeTab') === scOverviewScreenTabs.COMMUNICATION
            && !queryParams.get('serviceCaseId')
            && !queryParams.get('size')
            && !queryParams.get('page')
        ) {
            queryParams.append('serviceCaseId', serviceCase.id);
            queryParams.append('size', config.DEFAULT_PAGE_SIZE);
            queryParams.append('page', 0);

            history.replace(resolveRoute(routePaths.SERVICE_CASES_OVERVIEW, {
                serviceCaseId: serviceCase.id,
            }, {search: queryParams.toString()}));
        }
    }, [history, serviceCase.id, queryParams]);

    useEffect(() => {
        if (readMessageIds?.length === 0 || !shouldMarkMessageAsRead.current) return;
        updateMessageAsRead({messageIds: readMessageIds});
        shouldMarkMessageAsRead.current = true;
    }, [readMessageIds, updateMessageAsRead]);

    useEffect(() => {
        if (serviceCaseMessages.length === 0) return;
        setMessages(serviceCaseMessages.map(message => {
            return {
                ...message,
                ...(message.channel === alfMessageChannelTypes.ELLA && {
                    messageContentPreview: messageId && message.id === Number(messageId)
                        ? generateMessagePreview(message.content, true)
                        : generateMessagePreview(message.content, false),
                }),
            };
        }));
        const openedMessage = serviceCaseMessages.find(message => message.id === Number(messageId));
        if (!openedMessage) return;

        if (!openedMessage.isRead) setReadMessageIds(prevState => ([
            ...(prevState.filter(id => id !== openedMessage.id)),
            openedMessage.id,
        ]));
    }, [messageId, serviceCaseMessages]);

    useEffect(() => {
        serviceCaseInvoices.forEach(invoice => {
            if (invoice.accountParty) {
                recipientContactNames[invoice.accountParty.id] = invoice.accountParty.name;
            }
        });
    }, [serviceCaseInvoices, recipientContactNames]);

    const redirectToInvoiceDetails = invoiceId => {
        const invoice = serviceCaseInvoices.find(invoice => invoice.id === invoiceId);
        if (!invoice) return;

        const {status} = invoice;

        if (status === alfInvoiceStatusTypes.OPEN) return;

        if (status === alfInvoiceStatusTypes.IN_CREATION) {
            history.push(resolveRoute(routePaths.INVOICES, {
                serviceCaseId: invoice.serviceCaseId,
                invoiceId: invoice.id,
            }));
            return;
        }

        history.push(resolveRoute(routePaths.INVOICE_OVERVIEW, {
            serviceCaseId: invoice.serviceCaseId,
            invoiceId: invoice.id,
        }));
    };

    useBeforeUnloadEffect(event => {
        if (readMessageIds?.length === 0 || shouldMarkMessageAsRead.current) return;
        return {
            request: leaAssignmentFlowService.updateMessagesAsRead,
            requestData: {
                messageIds: readMessageIds,
            },
        };
    });

    const handleOnPaginationChange = page => {
        if (readMessageIds?.length > 0 && shouldMarkMessageAsRead.current) {
            updateMessageAsRead({messageIds: readMessageIds});
        }
        searchMessages({
            page,
            serviceCaseId: serviceCase?.id,
            size: config.DEFAULT_PAGE_SIZE,
        });
    };

    return (
        <Panel
            title={translateTab('messages_panel.title')}
            actions={isMessageCreationAllowed && (
                <ButtonPrimary onClick={() => initiateReplyUploadFlow()}>
                    <Icon
                        icon={uploadIcon}
                        className={cx([
                            'global!ace-u-margin--right-16',
                            'ace-c-icon--color-contrast',
                        ])}
                    />
                    {translateTab('button_label.upload_reply')}
                </ButtonPrimary>
            )}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table qaIdent="messages-overview-table">
                {messages?.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={historyIcon}
                                />
                            )}
                            description={translateTab('no_results.description')}
                            message={translateTab('no_results.message')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell
                            qaIdentPart="message-type"
                            colSpan={2}
                            className={cx('ace-u-padding--left-32')}
                        />
                        <TableCell qaIdentPart="created-date" colSpan={3}>
                            {translateTab('table_header.created_date')}
                        </TableCell>
                        <TableCell qaIdentPart="invoice-id" colSpan={2}>
                            {translateTab('table_header.invoice_id')}
                        </TableCell>
                        <TableCell qaIdentPart="channel" colSpan={3}>
                            {translateTab('table_header.communication_channel')}
                        </TableCell>
                        <TableCell qaIdentPart="message-subject" colSpan={9}>
                            {translateTab('table_header.subject')}
                        </TableCell>
                        <TableCell qaIdentPart="contact-name" colSpan={4}>
                            {translateTab('table_header.contact_name')}
                        </TableCell>
                        <TableCell qaIdentPart="user" colSpan={3}>
                            {translateTab('table_header.user')}
                        </TableCell>
                        <TableCell
                            qaIdentPart="attachment"
                            colSpan={2}
                            className={cx('ace-u-padding--right-32')}
                        >
                            {translateTab('table_header.attachment')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {messages?.length > 0 && messages.map(message => {
                        return (
                            <TableRow
                                key={`${message.serviceCaseId}-${message.id}`}
                                qaIdentPart={message.serviceCaseId}
                            >
                                <TableCell
                                    qaIdentPart="message-type"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={2}
                                    className={cx('ace-u-padding--left-32')}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-flex--justify-content-start',
                                        ])}
                                    >
                                        <Icon
                                            icon={message.messageType === alfMessageTypes.OUTGOING
                                                ? outgoingMessageIcon
                                                : incomingMessageIcon}
                                            className={cx('global!ace-u-flex--shrink-0', {
                                                'ace-c-icon--color-success': message.messageType === alfMessageTypes.INCOMING,
                                                'ace-c-icon--color-warning-dark': message.messageType === alfMessageTypes.OUTGOING,
                                            })}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell
                                    qaIdentPart="created-date"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={3}
                                >
                                    {message.createdAt || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="invoice-id"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={2}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            {
                                                'global!ace-u-typography--color-highlighted': message.invoiceId,
                                                'global!ace-u-cursor--pointer': message.invoiceId,
                                            },
                                        ])}
                                        onClick={() => redirectToInvoiceDetails(message.invoiceId)}
                                    >
                                        {message.invoiceId || '-'}
                                    </div>
                                </TableCell>
                                <TableCell
                                    qaIdentPart="channel"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={3}
                                >
                                    {translate(`global.communication_channel_option.${message.channel.toLowerCase()}`) || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="message-subject"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={9}
                                    className={cx([
                                        'ace-c-table__cell--overflow-visible',
                                        'ace-c-table__cell--white-space-normal',
                                    ])}
                                >
                                    {message.channel === alfMessageChannelTypes.ELLA ? (
                                        <div className={cx('global!ace-u-typography-word-break--break-all')}>
                                            {message.messageContentPreview}
                                            {message.content?.length > config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT
                                                ? (
                                                    <span
                                                        className={cx([
                                                            'global!ace-u-typography--variant-body-bold',
                                                            'global!ace-u-typography--color-highlighted',
                                                            'global!ace-u-cursor--pointer',
                                                        ])}
                                                        onClick={event => handleOnMessageContentExpansion(
                                                            message.id,
                                                            event,
                                                        )}
                                                    >
                                                        [{expandedMessageIds.includes(message.id)
                                                        ? translateTab('table_body.read_less')
                                                        : translateTab('table_body.read_more')}]
                                                    </span>
                                                ) : !message.isRead
                                                    && message.messageType === alfMessageTypes.INCOMING && (
                                                        <span
                                                            className={cx([
                                                                'global!ace-u-typography--variant-body-bold',
                                                                'global!ace-u-margin--left-4',
                                                                {
                                                                    'global!ace-u-typography--color-disabled': readMessageIds.includes(message.id),
                                                                    'global!ace-u-typography--color-highlighted': !readMessageIds.includes(message.id),
                                                                    'global!ace-u-cursor--pointer': !readMessageIds.includes(message.id),
                                                                },
                                                            ])}
                                                            onClick={() => { markMessageAsRead(message); }}
                                                        >
                                                            [{translateTab('table_body.read')}]
                                                        </span>
                                                )}
                                        </div>
                                    ) : message.subject || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="contact-name"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={4}
                                >
                                    {message.messageType === alfMessageTypes.OUTGOING
                                        ? message.recipient || '-'
                                        : message.sender || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="user"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={3}
                                >
                                    {message.creatorName || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="attachment"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={2}
                                    className={cx('ace-u-padding--right-32')}
                                >
                                    {message.attachments.length > 0 && (
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-flex--justify-content-center',
                                            ])}
                                        >
                                            <InteractiveIcon
                                                icon={downloadIcon}
                                                className={cx([
                                                    'ace-c-interactive-icon--primary',
                                                    'global!ace-u-flex--shrink-0',
                                                    'global!ace-u-cursor--pointer',
                                                ])}
                                                onClick={event => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    const fileName = `${message.invoiceId ? `${message.invoiceId}-` : ''}${message.category ? `${translate(`global.message_category_type.${message.category.toLowerCase()}`)}-` : ''}${translateTab('file_name_text.attachment')}.zip`;
                                                    initiateDownloadAllDocuments({
                                                        attachments: message.attachments,
                                                        fileName: fileName.replaceAll(/\s+/g, ''),
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}
                                    {message.attachments.length === 0 && (
                                        '-'
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {!!caseMessagesTotalCount && (
                <Paginator
                    page={caseMessagesPage}
                    count={Math.ceil(caseMessagesTotalCount / config.DEFAULT_PAGE_SIZE)}
                    onClick={page => handleOnPaginationChange(page)}
                />
            )}
        </Panel>
    );
};

SCCommunicationTab.propTypes = {
    initiateReplyUploadFlow: PropTypes.func.isRequired,
    initiateDownloadAllDocuments: PropTypes.func.isRequired,
    searchMessages: PropTypes.func.isRequired,
    serviceCaseInvoices: PropTypes.array,
    serviceCaseMessages: PropTypes.array,
    history: PropTypes.object,
    serviceCase: PropTypes.object,
    updateMessageAsRead: PropTypes.func.isRequired,
    caseMessagesTotalCount: PropTypes.number,
    caseMessagesPage: PropTypes.number,
};

SCCommunicationTab.defaultProps = {
    serviceCaseInvoices: [],
    serviceCaseMessages: [],
    history: null,
    serviceCase: null,
    caseMessagesTotalCount: 0,
    caseMessagesPage: 0,
};

const mapStateToProps = (state, props) => {
    const serviceCaseInvoicesSelector = invoiceSelectors.createServiceCaseInvoicesSelector();
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCaseInvoices: serviceCaseInvoicesSelector(state, props),
        serviceCaseMessages: state.communication.messagesSearchResults,
        caseMessagesTotalCount: state.communication.messagesSearchTotalCount,
        caseMessagesPage: state.communication.messagesSearchResultsPage,
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateReplyUploadFlow: () => dispatch({
        type: communicationActionTypes.INITIATE_UPLOAD_REPLY_FLOW,
    }),
    initiateDownloadAllDocuments: payload => dispatch({
        type: communicationActionTypes.INITIATE_MESSAGE_ATTACHMENTS_DOWNLOAD_FLOW,
        payload,
    }),
    updateMessageAsRead: payload => dispatch({
        type: communicationActionTypes.UPDATE_MESSAGE_AS_READ,
        payload,
    }),
    searchMessages: payload => dispatch({
        type: communicationActionTypes.SEARCH_MESSAGES,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SCCommunicationTab));
