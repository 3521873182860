import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {ContentItem, Panel, useStyles, LoaderSpinner} from '@ace-de/ui-components';
import {Icon} from '@ace-de/ui-components/icons';

const ReporterTile = props => {
    const {cx} = useStyles();
    const {color, isPersistenceStatePending, amount, icon, name, redirectionPath, history} = props;
    const [isTileHovered, setIsTileHovered] = useState();

    return (
        <ContentItem className={cx('global!ace-u-grid-column--span-4')}>
            <Panel className={cx('ace-c-panel--full-bleed')}>
                <div
                    className={cx([
                        'global!ace-u-grid',
                        'global!ace-u-cursor--pointer',
                    ])}
                    onClick={() => {
                        if (redirectionPath) history.push(redirectionPath);
                    }}
                    onMouseEnter={() => setIsTileHovered(true)}
                    onMouseLeave={() => setIsTileHovered(false)}
                >
                    <div
                        className={cx([
                            'global!ace-u-grid-column--span-3',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-typography--variant-h2',
                            'global!ace-u-flex--justify-center',
                            `global!ace-u-box-skin--color-${color}`,
                        ])}
                    >
                        {isPersistenceStatePending ? <LoaderSpinner className={cx('ace-c-loader-spinner--s')} /> : amount}
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-grid-column--span-9',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-padding--48',
                            'global!ace-u-typography--variant-highlight',
                            {
                                'global!ace-u-typography--color-highlighted': isTileHovered,
                            },
                        ])}
                    >
                        <Icon
                            icon={icon}
                            className={cx([
                                'global!ace-u-margin--right-8',
                                {
                                    'ace-c-icon--color-highlight': isTileHovered,
                                },
                            ])}
                        />
                        {name}
                    </div>
                </div>
            </Panel>
        </ContentItem>
    );
};

ReporterTile.propTypes = {
    color: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    redirectionPath: PropTypes.object.isRequired,
    isPersistenceStatePending: PropTypes.bool.isRequired,
};

export default ReporterTile;
