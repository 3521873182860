import {put, select} from 'redux-saga/effects';
import {alfInvoiceStatusTypes} from '@ace-de/eua-entity-types';
import {replace, resolveRoute} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import invoiceOverviewScreenTabs from '../invoiceOverviewScreenTabs';

const redirectInvoiceScreen = function* redirectInvoiceScreen({payload}) {
    const {match, location} = payload;
    const {params, path} = match;
    const {invoiceId, serviceCaseId} = params;

    if (!invoiceId || !serviceCaseId || (!!location.state?.modalId && !location.state?.isModalClosed)) return;

    const {invoices} = yield select(state => state.invoices);
    const status = invoices[invoiceId]?.status;

    if (status === alfInvoiceStatusTypes.IN_CREATION && path !== routePaths.INVOICES) {
        yield put(replace(resolveRoute(routePaths.INVOICES, {
            serviceCaseId,
            invoiceId,
        })));
    }

    if ((status === alfInvoiceStatusTypes.MANUAL_CHECK || status === alfInvoiceStatusTypes.APPROVED
                || status === alfInvoiceStatusTypes.PAID || status === alfInvoiceStatusTypes.CLOSED
                || status === alfInvoiceStatusTypes.DECLINED)
     && path !== routePaths.INVOICE_OVERVIEW) {
        yield put(replace(resolveRoute(routePaths.INVOICE_OVERVIEW, {
            serviceCaseId,
            invoiceId,
        }, {activeTab: invoiceOverviewScreenTabs.AUDITING})));
    }

    if (status === alfInvoiceStatusTypes.OPEN && path !== routePaths.SERVICE_CASES_OVERVIEW) {
        yield put(replace(resolveRoute(routePaths.SERVICE_CASES_OVERVIEW, {serviceCaseId})));
    }
};

export default redirectInvoiceScreen;
