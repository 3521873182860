import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Divider, noLocationIcon, useStyles} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import {locationInsideIcon, Icon, locationOutsideIcon, accidentIcon, flagIcon, homeIcon, officeIcon, adminIcon} from '@ace-de/ui-components/icons';
import {alfRelevantLocationTypes} from '@ace-de/eua-entity-types';

const InvoiceRelevantLocationsSidePanel = props => {
    const {cx} = useStyles();
    const {locations, serviceCase, invoice, isMemberLocationMissing} = props;
    const {member} = serviceCase;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateComponent = createTranslateShorthand('relevant_locations_side_panel');

    const memberAddress = member?.personalDetails?.address?.formattedAddress
        ? member?.personalDetails?.address.formattedAddress
        : [
            member?.personalDetails?.address?.street,
            [member?.personalDetails?.address?.postCode, member?.personalDetails?.address?.city].filter(value => !!value).join(' '),
            member?.personalDetails?.address?.country,
        ].filter(addressComponent => !!addressComponent).join(', ') || '-';

    const towingLocation = locations.find(location => {
        return location.type === alfRelevantLocationTypes.TOWING;
    });
    const finalTowingLocation = locations.find(location => {
        return location.type === alfRelevantLocationTypes.FINAL_TOWING
            || location.type.includes(alfRelevantLocationTypes.FINAL_TOWING);
    });
    const dropOffLocation = locations.find(location => {
        return location.type === alfRelevantLocationTypes.DROP_OFF;
    });
    const dropOffAddress = dropOffLocation?.address?.formattedAddress
        || [dropOffLocation?.address?.street, dropOffLocation?.address?.postCode, dropOffLocation?.address?.city]
            .filter(value => (!!value)).join(', ');
    const towingLocationAddress = towingLocation?.address?.formattedAddress
        || [towingLocation?.address?.street, towingLocation?.address?.postCode, towingLocation?.address?.city]
            .filter(value => (!!value)).join(', ');
    const finalTowingLocationAddress = finalTowingLocation?.address?.formattedAddress || [
        finalTowingLocation?.address?.street,
        finalTowingLocation?.address?.postCode,
        finalTowingLocation?.address?.city,
    ].filter(value => (!!value)).join(', ');
    const invoiceLineLocations = invoice?.lines?.map(invoiceLine => invoiceLine.destination)
        .filter(destination => !!destination) || [];
    const formattedAddress = locations.find(location => {
        return location.type === alfRelevantLocationTypes.DAMAGE;
    })?.address?.formattedAddress
        || serviceCase?.damage?.location?.address?.formattedAddress
        || serviceCase?.damage?.location?.address?.displayAddress;

    const acePartner = locations.find(location => {
        return location.type === alfRelevantLocationTypes.ACE_PARTNER;
    });
    const acePartnerAddress = acePartner?.address
        ? acePartner.address.formattedAddress
            ? acePartner.address.formattedAddress
            : [acePartner.address.street, acePartner.address.postCode, acePartner.address.city, acePartner.address.country].filter(addressComponent => !!addressComponent).join(', ')
        : '-';

    return (
        <div
            className={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--direction-column',
                'global!ace-u-flex--align-start',
                'global!ace-u-full-width',
            ])}
        >
            <div className={cx('global!ace-u-margin--bottom-16')}>
                <div
                    className={cx([
                        'global!ace-u-margin--bottom-16',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-flex-end',
                    ])}
                >
                    <Icon icon={officeIcon} />
                    <h4
                        className={cx([
                            'global!ace-u-margin--left-8',
                            'global!ace-u-typography--color-default',
                        ])}
                    >
                        {translateComponent('side_panel_title.contract_partner')}
                    </h4>
                </div>
                <p data-qa="invoice-service-provider">
                    {invoice?.lines?.length > 0 && invoice.lines[0].partnerName
                        ? invoice.lines[0].partnerName
                        : acePartner?.name
                            ? acePartner.name
                            : '-'}
                </p>
                <p data-qa="invoice-service-provider-address">
                    {invoice?.lines?.length > 0 && invoice?.lines[0].partnerAddress
                        ? invoice?.lines[0].partnerAddress
                        : acePartnerAddress
                        }
                </p>
            </div>
            <Divider />
            <div
                className={cx([
                    'global!ace-u-margin--bottom-16',
                    'global!ace-u-margin--top-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-margin--bottom-16',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-flex-end',
                    ])}
                >
                    <Icon icon={accidentIcon} />
                    <h4
                        className={cx([
                            'global!ace-u-margin--left-8',
                            'global!ace-u-typography--color-default',
                        ])}
                    >
                        {translateComponent('side_panel_title.damage_location')}
                    </h4>
                </div>
                <p data-qa="invoice-damage-location">
                    {formattedAddress || '-'}
                </p>
                {typeof (invoice?.damageLocationWithinArea) !== 'undefined' && (
                <Fragment>
                    <br />
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-full-width',
                        ])}
                        data-qa="invoice-damage-location-in-area"
                    >
                        <Icon
                            icon={invoice.damageLocationWithinArea
                                ? locationInsideIcon
                                : locationOutsideIcon
                                    }
                        />
                        <p>
                            {translateComponent(`side_panel_location_within_area.${invoice.damageLocationWithinArea}`)}
                        </p>
                    </div>
                </Fragment>
                )}
            </div>
            <Divider />
            {/* Todo this will be covered in the separate ticket */}
            {/* <div */}
            {/*    className={cx([ */}
            {/*        'global!ace-u-margin--bottom-16', */}
            {/*        'global!ace-u-margin--top-32', */}
            {/*    ])} */}
            {/* > */}
            {/*    <h4 className={cx('global!ace-u-margin--bottom-16')}> */}
            {/*        {translateComponent('side_panel_title.starting_point')} */}
            {/*    </h4> */}
            {/*    <p> */}
            {/*        {formattedAddress || '-'} */}
            {/*    </p> */}
            {/* </div> */}
            {/* <hr /> */}
            <div
                className={cx([
                    'global!ace-u-margin--bottom-16',
                    'global!ace-u-margin--top-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-margin--bottom-16',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-flex-end',
                    ])}
                >
                    <Icon icon={flagIcon} />
                    <h4
                        className={cx([
                            'global!ace-u-margin--left-8',
                            'global!ace-u-typography--color-default',
                        ])}
                    >
                        {translateComponent('side_panel_title.final_towing_destination')}
                    </h4>
                </div>
                <p data-qa="invoice-towing-destination-name">
                    {finalTowingLocation?.name || towingLocation?.name || dropOffLocation?.name || '-'}
                </p>
                <p data-qa="invoice-towing-destination-address">
                    {
                    finalTowingLocationAddress
                        || towingLocationAddress
                        || dropOffAddress
                        || '-'
                    }
                </p>
                {typeof (invoice?.towingDestinationWithinArea) !== 'undefined' && (
                <Fragment>
                    <br />
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-full-width',
                        ])}
                        data-qa="invoice-towing-destination-in-area"
                    >
                        <Icon
                            icon={invoice.towingDestinationWithinArea
                                ? locationInsideIcon
                                : locationOutsideIcon
                                    }
                        />
                        <p>
                            {translateComponent(`side_panel_location_within_area.${invoice.towingDestinationWithinArea}`)}
                        </p>
                    </div>
                </Fragment>
                )}
            </div>
            <Divider />
            <div
                className={cx([
                    'global!ace-u-margin--bottom-16',
                    'global!ace-u-margin--top-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-margin--bottom-16',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-flex-end',
                    ])}
                >
                    <Icon icon={adminIcon} />
                    <h4
                        className={cx([
                            'global!ace-u-margin--left-8',
                            'global!ace-u-typography--color-default',
                        ])}
                    >
                        {translateComponent('side_panel_title.towing_destination_ecs')}
                    </h4>
                </div>
                <p data-qa="invoice-towing-destination-ecs">
                    {towingLocationAddress || '-'}
                </p>
            </div>
            <Divider />
            <div
                className={cx([
                    'global!ace-u-margin--bottom-16',
                    'global!ace-u-margin--top-32',
                ])}
            >
                <h4
                    className={cx([
                        'global!ace-u-margin--bottom-16',
                        'global!ace-u-typography--color-default',
                    ])}
                >
                    {translateComponent('side_panel_title.distance_route')}
                </h4>
                <p data-qa="invoice-distance-provider-damage-towing-provider">
                    {invoice.providerDamageTowingProviderDistance
                    || parseInt(invoice.providerDamageTowingProviderDistance, 10) === 0
                        ? `${Math.round(invoice.providerDamageTowingProviderDistance)} ${translate('global.unit.km')}`
                        : '-'
                        }
                </p>
            </div>
            <Divider />
            <div
                className={cx([
                    'global!ace-u-margin--bottom-16',
                    'global!ace-u-margin--top-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-margin--bottom-16',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-flex-end',
                    ])}
                >
                    <Icon icon={homeIcon} />
                    <h4
                        className={cx([
                            'global!ace-u-margin--left-8',
                            'global!ace-u-margin--right-8',
                            'global!ace-u-typography--color-default',
                        ])}
                    >
                        {translateComponent('side_panel_title.member_address')}
                    </h4>
                    {isMemberLocationMissing && <Icon icon={noLocationIcon} />}
                </div>
                <p data-qa="invoice-member-address">
                    {memberAddress}
                </p>
            </div>
            <Divider />
            <div
                className={cx([
                    'global!ace-u-margin--bottom-16',
                    'global!ace-u-margin--top-32',
                ])}
            >
                <h4
                    className={cx([
                        'global!ace-u-margin--bottom-16',
                        'global!ace-u-typography--color-default',
                    ])}
                >
                    {translateComponent('side_panel_title.distance_member_to_damage')}
                </h4>
                <p data-qa="invoice-residence-to-damage-location-distance">
                    {serviceCase.residenceToDamageLocationDistance
                    || parseInt(serviceCase.residenceToDamageLocationDistance, 10) === 0
                        ? `${Math.round(serviceCase.residenceToDamageLocationDistance)} ${translate('global.unit.km')}`
                        : invoice.residenceToDamageLocationDistance
                            || parseInt(invoice.residenceToDamageLocationDistance, 10)
                            ? `${Math.round(invoice.residenceToDamageLocationDistance)} ${translate('global.unit.km')}`
                            : '-'
                        }
                </p>
            </div>
            {invoiceLineLocations.length > 0 && (<Divider />)}
            {invoiceLineLocations && invoiceLineLocations.map(invoiceLineLocation => {
                return (
                    <div
                        key={invoiceLineLocation.id}
                        className={cx([
                            'global!ace-u-margin--bottom-16',
                            'global!ace-u-margin--top-32',
                        ])}
                    >
                        <div
                            className={cx([
                                'global!ace-u-margin--bottom-16',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-flex-end',
                            ])}
                        >
                            <Icon icon={flagIcon} />
                            <h4
                                className={cx([
                                    'global!ace-u-margin--left-8',
                                    'global!ace-u-typography--color-default',
                                ])}
                            >
                                {translateComponent('side_panel_title.destination')}
                            </h4>
                        </div>
                        <p>
                            {invoiceLineLocation?.address?.formattedAddress || '-'}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

InvoiceRelevantLocationsSidePanel.propTypes = {
    serviceCase: PropTypes.object,
    invoice: PropTypes.object,
    locations: PropTypes.array,
    isMemberLocationMissing: PropTypes.bool,
};

InvoiceRelevantLocationsSidePanel.defaultProps = {
    serviceCase: null,
    invoice: null,
    locations: [],
    isMemberLocationMissing: false,
};

const mapStateToProps = state => {
    return {
        isMemberLocationMissing: state.invoices.isMemberLocationMissing,
    };
};

export default connect(mapStateToProps, null)(InvoiceRelevantLocationsSidePanel);
